export enum ActionTypes {
  //AUTH_TYPES
  LOGIN_START = "LOGIN_START",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_ERROR = "LOGIN_ERROR",
  SIGNUP_START = "SIGNUP_START",
  SIGNUP_SUCCESS = "SIGNUP_SUCCESS",
  SIGNUP_ERROR = "SIGNUP_ERROR",
  LOGOUT = "LOGOUT",
  CLEAR_SIGNUP = "CLEAR_SIGNUP",
  CLEAR_ERROR = "CLEAR_ERROR",
  //DATASET_TYPES
  FETCH_DATASETS_START = "FETCH_DATASETS_START",
  FETCH_DATASETS_SUCCESS = "FETCH_DATASETS_SUCCESS",
  FETCH_DATASETS_ERROR = "FETCH_DATASETS_ERROR",
  CLEAR_FETCH_DATASETS_ERROR = "CLEAR_FETCH_DATASETS_ERROR",
  CHANGE_DATASET_FILTER_BY_OWN = "CHANGE_DATASET_FILTER_BY_OWN",
  CHANGE_DATASET_FILTER_BY_TYPE = "CHANGE_DATASET_FILTER_BY_TYPE",
  //CREATE AND UPDATE DATASET_TYPES
  START_CREATE_DATASET = "START_CREATE_DATASET",
  HANDLE_DATASET_ON_CHANGE = "HANDLE_DATASET_ON_CHANGE",
  CANCEL_UPDATE_DATASET = "CANCEL_UPDATE_DATASET",
  CREATE_DATASET_START = "CREATE_DATASET_START",
  CREATE_DATASET_SUCCESS = "CREATE_DATASET_SUCCESS",
  CREATE_DATASET_ERROR = "CREATE_DATASET_ERROR",
  CLEAR_CREATE_DATASET_ERROR = "CLEAR_CREATE_DATASET_ERROR",
  //DELETE DATASET BY ID
  DELETE_DATASET_START = "DELETE_DATASET_START",
  DELETE_DATASET_SUCCESS = "DELETE_DATASET_SUCCESS",
  DELETE_DATASET_ERROR = "DELETE_DATASET_ERROR",
  CLEAR_DELETE_DATASET_ERROR = "CLEAR_DELETE_DATASET_ERROR",
  CLEAR_DELETE_DATASET_SUCCESS = "CLEAR_DELETE_DATASET_SUCCESS",
  //Add images TO DATASETS
  SET_DATASET_ID_FOR_INSERTING = "SET_DATASET_ID_FOR_INSERTING",
  CANCEL_IMAGES_INSERTING = "CANCEL_IMAGES_INSERTING",
  CLEAR_ADD_IMAGES_ERROR = "CLEAR_ADD_IMAGES_ERROR",
  CLEAR_ADD_IMAGES_SUCCESS = "CLEAR_ADD_IMAGES_SUCCESS",
  ADD_IMAGES_TO_GROUP_START = "ADD_IMAGES_TO_GROUP_START",
  ADD_IMAGES_TO_GROUP_SUCCESS = "ADD_IMAGES_TO_GROUP_SUCCESS",
  ADD_IMAGES_TO_GROUP_ERROR = "ADD_IMAGES_TO_GROUP_ERROR",
  FETCH_SOURCE_FOLDER_START = "FETCH_SOURCE_FOLDER_START",
  FETCH_SOURCE_FOLDER_SUCCESS = "FETCH_SOURCE_FOLDER_SUCCESS",
  FETCH_SOURCE_FOLDER_ERROR = "FETCH_SOURCE_FOLDER_ERROR",
  DELETE_SOURCE_BY_INDEX = "DELETE_SOURCE_BY_INDEX",
  INSERTING_IMAGES_START = "INSERTING_IMAGES_START",
  INSERTING_IMAGES_SUCCESS = "INSERTING_IMAGES_SUCCESS",
  INSERTING_IMAGES_ERROR = "INSERTING_IMAGES_ERROR",
  //ON GOING LABEL
  SET_ON_GOING_LABAL_DATASET = "SET_ON_GOING_LABAL_DATASET",
  SET_ON_GOING_LABAL_DATASET_NAVIGATE_TO = "SET_ON_GOING_LABAL_DATASET_NAVIGATE_TO",
  TOGGLE_LABELING_REFRESH = "TOGGLE_LABELING_REFRESH",
  TOGGLE_LABELING_MODEL = "TOGGLE_LABELING_MODEL",
  DATASET_IMAGES_QUERYING_START = "DATASET_IMAGES_QUERYING_START",
  DATASET_IMAGES_QUERYING_SUCCESS = "DATASET_IMAGES_QUERYING_SUCCESS",
  DATASET_IMAGES_QUERYING_ERROR = "DATASET_IMAGES_QUERYING_ERROR",
  DATASET_IMAGE_FETCHING_START = "DATASET_IMAGE_FETCHING_START",
  DATASET_IMAGE_FETCHING_SUCCESS = "DATASET_IMAGE_FETCHING_SUCCESS",
  DATASET_IMAGE_FETCHING_ERROR = "DATASET_IMAGE_FETCHING_ERROR",
  DATASET_IMAGE_NAVIGATE_TO = "DATASET_IMAGE_NAVIGATE_TO",

  SET_CURRENT_LABEL_ELEMENTS = "SET_CURRENT_LABEL_ELEMENTS",
  SET_CURRENT_LABEL_ID = "SET_CURRENT_LABEL_ID",
  SET_CURRENT_HOVER_LABEL_ELEMENT = "SET_CURRENT_HOVER_LABEL_ELEMENT",
  SET_CURRENT_DATASET_ID = "SET_CURRENT_DATASET_ID",
  
  SET_LABEL_SUMMARY = "SET_LABEL_SUMMARY",
  INIT_LABEL_SUMMARY="INIT_LABEL_SUMMARY",

  SET_CLASS_SUMMARY = "SET_CLASS_SUMMARY",
  INIT_CLASS_SUMMARY="INIT_CLASS_SUMMARY",
  DELETE_LABEL_ALL = "DELETE_LABEL_ALL",
  DELETE_LABEL_BY_INDEX = "DELETE_LABEL_BY_INDEX",
  INSERT_DETECT_LABELS_TO_IMAGE_START = "INSERT_DETECT_LABELS_TO_IMAGE_START",
  INSERT_DETECT_LABELS_TO_IMAGE_SUCESS = "INSERT_DETECT_LABELS_TO_IMAGE_SUCESS",
  INSERT_DETECT_LABELS_TO_IMAGE_FAIL = "INSERT_DETECT_LABELS_TO_IMAGE_FAIL",
  DELETE_DATASET_IMAGE_BY_ID_START = "DELETE_DATASET_IMAGE_BY_ID_START",
  DELETE_DATASET_IMAGE_BY_ID_SUCCESS = "DELETE_DATASET_IMAGE_BY_ID_SUCCESS",
  DELETE_DATASET_IMAGE_BY_ID_FAIL = "DELETE_DATASET_IMAGE_BY_ID_FAIL",
  SET_CURRENT_LABEL_CLASSIFICATION = "SET_CURRENT_LABEL_CLASSIFICATION",

  //TABLE CLASSIFICATIONS
  RENAME_CLASSIFICATION_START = "RENAME_CLASSIFICATION_START",
  RENAME_CLASSIFICATION_SUCCESS = "RENAME_CLASSIFICATION_SUCCESS",
  RENAME_CLASSIFICATION_ERROR = "RENAME_CLASSIFICATION_ERROR",
  DELETE_CLASSIFICATION_START = "DELETE_CLASSIFICATION_START",
  DELETE_CLASSIFICATION_SUCCESS = "DELETE_CLASSIFICATION_SUCCESS",
  DELETE_CLASSIFICATION_ERROR = "DELETE_CLASSIFICATION_ERROR",
  ADD_CLASSIFICATION_START = "ADD_CLASSIFICATION_START",
  ADD_CLASSIFICATION_SUCCESS = "ADD_CLASSIFICATION_SUCCESS",
  ADD_CLASSIFICATION_ERROR = "ADD_CLASSIFICATION_ERROR",
  SET_TABLE_CLASSIFICATION_EDIT_OPEN = "SET_TABLE_CLASSIFICATION_EDIT_OPEN",
  SET_TABLE_CLASSIFICATION_EDIT_CLOSE = "SET_TABLE_CLASSIFICATION_EDIT_CLOSE",

  //
  DATASET_IMAGE_IS_UPDATE = "DATASET_IMAGE_IS_UPDATE",
}



import { ActionTypes } from "../action-types/index";
import * as Actions from "../actions";

export interface deleteDatasetState {
  isDeletePending: boolean;
  isDeleteSuccess: boolean;
  error: string[];
}

const INIT_STATE: deleteDatasetState = {
  isDeletePending: false,
  isDeleteSuccess: false,
  error: [],
};

const reducer = (state = INIT_STATE, action: Actions.DeleteDatasetActions) => {
  switch (action.type) {
    case ActionTypes.DELETE_DATASET_START:
      return {
        ...INIT_STATE,
        isDeletePending: true,
      };
    case ActionTypes.DELETE_DATASET_SUCCESS:
      return {
        ...INIT_STATE,
        isDeletePending: false,
        isDeleteSuccess: true,
      };
    case ActionTypes.DELETE_DATASET_ERROR:
      return {
        ...state,
        isDeletePending: false,
        error: action.error,
      };
    case ActionTypes.CLEAR_DELETE_DATASET_ERROR:
      return {
        ...state,
        error: [],
      };
    case ActionTypes.CLEAR_DELETE_DATASET_SUCCESS:
      return {
        ...state,
        isDeleteSuccess: false,
      };
    default:
      return state;
  }
};

export default reducer;

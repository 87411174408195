import { useEffect, useState } from "react";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { Form, Input, Button, Divider, Typography, message } from "antd";
import styled from "@emotion/styled";
//import logo from "../../assest/foresight.png";
import logo from "../../assest/mic.logo.svg";
import blogo from "../../assest/fst.logo.svg";
import aflogo from "../../assest/aiotxfst.logo.png";
import { fstlogo } from "../../urls/index";

const { Title, Paragraph, Text } = Typography;
const Container = styled.div`
  min-width: 400px;
  width: 30%;
  background-color: rgba(247, 250, 243, 0.658);
  padding: 3rem 2rem;
  border-radius: 5px;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2);
`;
const TitleContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 10, span: 16 },
};

const SignInAndSignUp = () => {
  const { error, isAuthPending, signUpSuccess } = useTypedSelector(
    (state) => state.auth
  );
  const { login, signUp, cleanSignUp, cleanError } = useActions();
  const [isLogin, setIslogin] = useState(true);

  useEffect(() => {
    if (signUpSuccess) {
      message.success("Account successfully sign up!", 3, () => {
        cleanSignUp();
      });
    }
  }, [signUpSuccess, cleanSignUp]);
  useEffect(() => {
    if (error) {
      message.error(error, 3, () => {
        cleanError();
      });
    }
  }, [error, cleanError]);

  const onFinish = async (values: { username: string; password: string }) => {
    const { username, password } = values;
    if (isLogin) {
      return login(username, password);
    }
    return signUp(username, password);
  };

  // const onFinishFailed = (errorInfo: any) => {
  //   console.log("Failed:", errorInfo);
  // };
  // initialValues={{ remember: true, username: "user001", password:"User*001" }}

  return (
    <Container>
      <TitleContainer>
        {/* Function Remark */}
        {fstlogo &&
          <img src={blogo} alt="logo" style={{marginBottom:"1rem", width:300}}/>
        }
        {!fstlogo &&
          <img src={logo} alt="logo" style={{marginBottom:"1.5rem"}}/>
        }
        <Title level={2}>Super Vision</Title>
      </TitleContainer>
      <Divider />
      <Form
        {...layout}
        name="basic"
        onFinish={onFinish}
        scrollToFirstError
        // Function Remark 
        //initialValues={{ remember: true, username: "user001", password:"User*001" }}
        // onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Username"
          name="username"
          rules={
            isLogin
              ? [{ required: true, message: "Please input your username!" }]
              : [
                  { required: true, message: "Please input your username!" },
                  { min: 4, message: "username must longer than 4 characters" },
                  {
                    max: 20,
                    message: "username must not longer than 20 characters",
                  },
                ]
          }
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={
            isLogin
              ? [{ required: true, message: "Please input your password!" }]
              : [
                  { required: true, message: "Please input your password!" },
                  { min: 4, message: "password must longer than 4 characters" },
                  {
                    pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm,
                    message:
                      "at least one lowercase letter and one symbol or number letter",
                  },
                ]
          }
        >
          <Input.Password />
        </Form.Item>
        {
        <Paragraph style={{ textAlign: "center" }}>
          {isLogin
            ? "doesn't have an account ? "
            : "already have an account ? "}
          <Text onClick={() => setIslogin(!isLogin)} style={{ color: "blue" }}>
            {isLogin ? "Sign Up" : "Login"}
          </Text>
        </Paragraph>
          }
        <Form.Item {...tailLayout}>
          <Button loading={isAuthPending} type="primary" htmlType="submit">
            {isLogin ? "LOGIN" : "SIGN UP"}
          </Button>
        </Form.Item>
      </Form>
    </Container>
  );
};

export default SignInAndSignUp;

import { Modal } from "antd";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import { TableClassificationEditTask } from "../../states/reducers/tableClassifications";
import { useEffect } from "react";
export interface ClassificationEditDeleteProps {
  editTask: TableClassificationEditTask;
}

const ClassificationEditDelete: React.FC<ClassificationEditDeleteProps> = ({
  editTask,
}) => {
  const {
    setClassificationEditClose,
    deleteClassificationAsync,
    fetchDatasetsAsync,
  } = useActions();
  useEffect(() => {
    return () => {
      fetchDatasetsAsync();
    };
  }, [fetchDatasetsAsync]);

  const { targetClassification, isClassificationUpdating } = useTypedSelector(
    (state) => state.classifications
  );
  const onFinish = () => {
    deleteClassificationAsync(targetClassification!.id);
  };
  return (
    <Modal
      title="Delete This Classification"
      confirmLoading={isClassificationUpdating}
      onCancel={setClassificationEditClose}
      onOk={onFinish}
      visible={editTask !== TableClassificationEditTask.NONE}
    >
      <p>
        <span style={{ color: "red" }}>
          !!Danger !! Delete this Classification :
          <span style={{ color: "blue" }}>
            {targetClassification?.description}
          </span>{" "}
          will also delete all related labels
        </span>
      </p>
    </Modal>
  );
};

export default ClassificationEditDelete;

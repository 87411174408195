import axios from "axios";
import * as Actions from "../actions";
import { Dispatch } from "redux";
import { ActionTypes } from "../action-types/index";
import { offlineUrl,offlineUrlExpress } from "../../urls/index";
import * as _ from "lodash";
import { FilterByOwn, FilterByType } from "../reducers/datasets";

export const fetchDatasetsAsync = () => async (
  dispatch: Dispatch<Actions.DatasetsAction>
) => {
  dispatch({
    type: ActionTypes.FETCH_DATASETS_START,
  });
  const jwt = localStorage.getItem("jwt");
  try {
    /* patch
    const { data } = await axios.get(`${offlineUrl}/datasets`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    */
    const { data } = await axios.get(`${offlineUrlExpress}/api/datasets`);   
    dispatch({
      type: ActionTypes.FETCH_DATASETS_SUCCESS,
      datasets: data,
    });
  } catch (err:any)  {
    const errMsg = [];

    if (err.response) {
      if (!_.isArray(err.response.data.message)) {
        errMsg.push(err.response.data.message);
      }
    } else {
      errMsg.push(err.message);
    }
    dispatch({
      type: ActionTypes.FETCH_DATASETS_ERROR,
      error: errMsg,
    });
  }
};

export const clearFetchDatasetsError = (): Actions.clearFetchDatasetsError => ({
  type: ActionTypes.CLEAR_FETCH_DATASETS_ERROR,
});

export const changeDatasetFilterByOwn = (
  changeTo: FilterByOwn
): Actions.changeDatasetFilterByOwn => ({
  type: ActionTypes.CHANGE_DATASET_FILTER_BY_OWN,
  to: changeTo,
});
export const changeDatasetFilterByType = (
  changeTo: FilterByType
): Actions.changeDatasetFilterByType => ({
  type: ActionTypes.CHANGE_DATASET_FILTER_BY_TYPE,
  to: changeTo,
});

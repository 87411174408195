import { ActionTypes } from "../action-types";
import * as Actions from "../actions";
export interface Classification {
  id: number;
  description: string;
};

export enum FilterByOwn {
  USER = "USER",
  OTHERS = "OTHERS",
  ALL = "ALL",
}

export enum FilterByType {
  ALL = "ALL",
  DETECT = "DETECT",
  CLASSIFY = "CLASSIFY",
  DETECT_AND_CLASSIFY = "DETECT&CLASSIFY",
  ABNORMAL = "ABNORMAL",
}

export interface Dataset {
  id: number;
  name: string;
  type: string;
  user: string;
  create_at: string;
  hasLabeled: number;
  imagesCount: number;
  classifications: Classification[];
}

interface DatasetState {
  datasets: Dataset[];
  isDatasetPending: boolean;
  error: string[];
  filterByOwn: FilterByOwn;
  filterByType: FilterByType;
}

const INITAILS_STATE: DatasetState = {
  datasets: [],
  isDatasetPending: false,
  error: [],
  filterByOwn: FilterByOwn.USER,
  filterByType: FilterByType.ALL,
};
const reducer = (state = INITAILS_STATE, action: Actions.DatasetsAction) => {
  switch (action.type) {
    case ActionTypes.FETCH_DATASETS_START:
      return {
        ...state,
        isDatasetPending: true,
        error: [],
      };
    case ActionTypes.FETCH_DATASETS_SUCCESS:
      return {
        ...state,
        isDatasetPending: false,
        datasets: action.datasets,
        error: [],
      };
    case ActionTypes.FETCH_DATASETS_ERROR:
      return {
        ...state,
        isDatasetPending: false,
        error: action.error,
      };
    case ActionTypes.CLEAR_FETCH_DATASETS_ERROR:
      return {
        ...state,
        error: [],
      };
    case ActionTypes.CHANGE_DATASET_FILTER_BY_OWN:
      return {
        ...state,
        filterByOwn: action.to,
      };
    case ActionTypes.CHANGE_DATASET_FILTER_BY_TYPE:
      return {
        ...state,
        filterByType: action.to,
      };
    default:
      return state;
  }
};

export default reducer;

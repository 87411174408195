import { Modal, Button, Select, Form, List, Card, Row, Col, Input } from 'antd';

import React, { useState } from 'react';

import TasksValidTest from '../../components/Tasks-Valid-Test/index'

export interface TestContainerProps { }

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const getFields = () => {
//const count = expand ? 10 : 6;
  const children = [];
  for (let i = 0; i < 6; i++) {
    children.push(
      <Col span={8} key={i}>
        <Form.Item
          name={`field-${i}`}
          label={`Field ${i}`}
          rules={[
            {
              required: true,
              message: 'Input something!',
            },
          ]}
        >
        <Select
          placeholder="Select a option and change input text above"
          //onChange={onGenderChange}
          //allowClear
        >
          <Option value="male">male</Option>
          <Option value="female">female</Option>
          <Option value="other">other</Option>
        </Select> 
          {/*<Input placeholder="placeholder" />*/}
        </Form.Item>
      </Col>,
    );
  }
  return children;
};

let listdata = [
  {
    cid: 1,
    title: 'Class1',
  },
  {
    cid: 2,
    title: 'Class2',
  },
  {
    cid: 3,
    title: 'Class3',
  },
  {
    cid: 4,
    title: 'Class4',
  },
];


let selectdata = [
  {
    cid: 1,
    title: 'Class1',
  },
  {
    cid: 2,
    title: 'Class2',
  },
  {
    cid: 3,
    title: 'Class3',
  },
  {
    cid: 4,
    title: 'Class4',
  },
];

listdata = [
  {
    cid: 1,
    title: 'Class1',
  },
  {
    cid: 2,
    title: 'Class2',
  },
  {
    cid: 3,
    title: 'Class3',
  },
  {
    cid: 4,
    title: 'Class4',
  },
  {
    cid: 5,
    title: 'Class5',
  },
  {
    cid: 6,
    title: 'Class6',
  },  
];


selectdata = [
  {
    cid: 1,
    title: 'Class1',
  },
  {
    cid: 2,
    title: 'Class2',
  },
  {
    cid: 3,
    title: 'Class3',
  },
  {
    cid: 4,
    title: 'Class4',
  },
  {
    cid: 5,
    title: 'Class5',
  },
  {
    cid: 6,
    title: 'Class6',
  },   
];
/*
selectdata.map(item => (
  console.log(item.cid + "," + item.title)
))
 */
/*
{selectdata.map(item => (
  <Select.Option key={item.cid} value={item.title}>
    {item}
  </Select.Option>
))}
*/


function handleChange(value: string) {
  console.log(`selected ${value}`);
}

const onFinish = (values: any) => {
  console.log(values);
};

const TestContainer: React.SFC<TestContainerProps> = () => {

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [getValidDatasetClass, setValidDatasetClass] = useState(listdata);
  const [getValidModalClass, setValidModalClass] = useState(selectdata);

  const setclassA = () =>{
    setValidDatasetClass(selectdata)
    setValidModalClass(listdata)
  }

  const setclassB = () =>{
    setValidDatasetClass([
      {
        cid: 1,
        title: 'Class1',
      },
      {
        cid: 2,
        title: 'Class2',
      }])
    setValidModalClass([
      {
        cid: 1,
        title: 'Class1',
      },
      {
        cid: 2,
        title: 'Class2',
      }])
  }

  const [form] = Form.useForm();

  const onCreate = (values: any) => {
    console.log('Received values of form: ', values);
    setIsModalVisible(false);
  };

  const showModal = () => {
    form.resetFields();
    setIsModalVisible(true);
  };

  const handleOk = () => {
    form
    .validateFields()
    .then(values => {
      console.log(values);
      form.resetFields();
      onCreate(values);
    })
    .catch(info => {
      console.log('Validate Failed:', info);
    });
    form.resetFields();    
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };

  const handleChange = (text:any, index:any) => {
    console.log(index);
  };

  return (
    <>
      <TasksValidTest taskstitle="TasksList" taskstype="tasks"></TasksValidTest>
      <TasksValidTest taskstitle="ValidList" taskstype="valid"></TasksValidTest>
      <TasksValidTest taskstitle="TestList" taskstype="test"></TasksValidTest>

      <Button type="primary" onClick={showModal}>
        Open Modal
      </Button>

      <Button type="primary" onClick={setclassA}>
        Class A
      </Button>

      <Button type="primary" onClick={setclassB}>
        Class B
      </Button>

      <Modal title="Class Mapping" 
        visible={isModalVisible} 
        onOk={handleOk} 
        onCancel={handleCancel}
        width={800}>
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: 'male' }}
      >

        <List
          grid={{ gutter: 16, column: 4 }}
          dataSource={getValidModalClass}
          renderItem={item => (
            <List.Item>
              <Card title={item.title}>
              <Form.Item
              name={`${item.cid}`}
              rules={[
                {
                  required: true,
                },
              ]}
              >
              <Select style={{ width: 120 }} onChange={handleChange}>
                {getValidDatasetClass.map(sitem => (
                  <Option key={`${item.cid}_${sitem.cid}`} value={`${item.cid}_${sitem.cid}`}>
                    {sitem.title}
                  </Option>
                ))}
              </Select>
              </Form.Item>              
              </Card>
            </List.Item>
          )}
        />
        </Form>        

      </Modal>
    </>
  )
};

export default TestContainer;

import { combineReducers } from "redux";
import authReducer from "./auth";
import datasetsReducer from "./datasets";
import createDatasetReducer from "./createDataset";
import insertImagesReducer from "./insertImages";
import deleteDatasetReducer from "./deleteDataset";
import onGoinglabelReducer from "./onGoinglabel";
import tableClassificationsReducer from "./tableClassifications";

const rootReducer = combineReducers({
  auth: authReducer,
  datasets: datasetsReducer,
  createDataset: createDatasetReducer,
  insertImages: insertImagesReducer,
  deleteDataset: deleteDatasetReducer,
  onGoinglabel: onGoinglabelReducer,
  classifications: tableClassificationsReducer,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;

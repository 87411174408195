const origin = window.location.hostname;
// const origin = "192.168.2.20";
// const dash = "http://" + window.location.hostname + ":5050";
const online = window.location.origin;

export const onlineUrl = online + "/api";
//Function Remark
//export const offlineUrl = "http://" + origin + ":5000/api";
export const offlineUrl = onlineUrl;
//export const offlineUrlExpress = "http://" + origin + ":5500";
export const offlineUrlExpress = "https://hbvisionapi.fs-technology.com";
//export const offlineUrlExpress = "https://aiotappvisionapi.fs-technology.com";
//export const offlineUrlExpress = "https://aivisiondemo1api.fs-technology.com/";
export const fstlogo = true;
export const fullFunction = true;
export const headlogo = 2;
export const angleFunction = true;
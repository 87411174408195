import {
  Form,
  Button,
  InputNumber,
  Typography,
  message,
  Row, Col, Space,
} from 'antd';
import {useState,useEffect,useRef, memo, useMemo} from 'react'
import { offlineUrl, offlineUrlExpress } from "../../urls/index";
import axios from "axios";
import DatasetImageCropTools from "../Dataset-Image-Crop-Tools"
const { Title, Text, Link } = Typography;

export interface Dataset{
  id:number,
  type:string,
  name:string
};
export type DatasetType = Dataset;


export interface ImageDetail{
  url:string,
  type:string
};

export interface DetectLabelElement {
  topLeftX: number;
  topLeftY: number;
  bottomRightX: number;
  bottomRightY: number;
};
const init_position = {
  topLeftX: 0,
  topLeftY: 0,
  bottomRightX: 1,
  bottomRightY: 1
};

export interface DatasetImageCropProps {
  datasetData: Dataset|undefined,
  setDatasetPosition:React.Dispatch<React.SetStateAction<any>>;//for post
}

const DatasetImageCrop: React.FC<DatasetImageCropProps> = ({
  datasetData,
  setDatasetPosition,
}) => {

  const [displayImageRef, setDisplayImageRef] 
    = useState<HTMLImageElement | null>(null);
  const [imageWidthAndHeight,setImageWidthAndHeight] 
    = useState<[number,number] | null>(null);
  const [imageUrlList,setImageUrlList] 
    = useState<string|undefined>(undefined)
  const [renderImage,setRenderImage] 
    = useState<boolean>(false);
  const [total,setTotal] 
    = useState<number>(1); 
  const [count,setCount]
   = useState<number>(1);
  const [loading,setLoading] 
    = useState<boolean>(false);
  const [positionValue,setPositionValue] 
    = useState<DetectLabelElement>({...init_position});
  const [positionState,setPositionState] 
    = useState<boolean>(false);
  const [originRegion,setOriginRegion]
    = useState<DetectLabelElement|null>(null);
  const [formDatasetCropImage] = Form.useForm();
  const imageRef = useRef<HTMLDivElement | null>(null);

  const changeImage =(action:string)=>{
    if(action === 'next'){
      setCount(prev=>count+1>total?total:prev+=1);
    }else{
      setCount(prev=>count-1<=0?prev:prev-=1);
    }
  };
  const handleReset=()=>{
    if(originRegion){
      setPositionValue({...originRegion})
    }
  };
  const handleClear=()=>{
    setPositionState(true);
    setPositionValue({...init_position});
  };
  const valueToNumber = (value: number) => {
    try {
      let count = value.toFixed(2);
      return parseFloat(count);
    } catch (e) {
      return value;
    }
 }
  const fetch = async(datasetData:Dataset)=>{
    setLoading(true);
    let datasetId = datasetData.id;
    const jwt = localStorage.getItem("jwt");
    try {
        const { data } = await axios.get(
          `${offlineUrlExpress}/api/datasetcropimage/${datasetId}`,
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
              "Access-Control-Allow-Origin": "*",
            },
        });
        const cropRegion =await data.cropRegion;
        const imageList = await data.imagelists;
        if(!imageList){
          throw new Error();
        };
        setPositionValue({...cropRegion});
        setOriginRegion({...cropRegion});
        setPositionState(false);
        setTotal(imageList.length);
        setImageUrlList(imageList);
        setLoading(false);
      }catch(err:any) {
        console.log(err);
        message.error("no result");
      }
  };
  useEffect(() => {
    if(datasetData){
      fetch(datasetData);
      setCount(1);
    };
  },[datasetData]);

  useEffect(() => {

    formDatasetCropImage.setFieldsValue({
      topX: positionValue?.topLeftX,
      topY:positionValue?.topLeftY ,
      bottomX: positionValue?.bottomRightX,
      bottomY: positionValue?.bottomRightY
    });
    setDatasetPosition({...positionValue});//for post 
  },[positionValue]);


  return (
    <div style={{width:"100%",height:"620px"}}>
    <Row gutter={[32, 16]}>
      <Col className="gutter-row" span={8}>
          <Form form={formDatasetCropImage}
            labelCol={{ span: 9 }}
            wrapperCol={{ span: 8 }}
            layout="horizontal"
            style={{marginTop:"5em"}}
            initialValues={{ 
              topX: positionValue.topLeftX,
              topY:positionValue.topLeftY ,
              bottomX: positionValue.bottomRightX,
              bottomY: positionValue.bottomRightY }}
          >
            <Form.Item label="Dataset Name">
              <Text>DatasetName</Text>
            </Form.Item>
            <Form.Item label="Current">
              <Text> {count}</Text>
            </Form.Item>
            <Form.Item label="Total">
              <Text> {total}</Text>
            </Form.Item>
            <Form.Item label="Top Left (X)" name="topX" rules={[{ required: true, message: 'Data is Required' }]}>
              <InputNumber min={0} max={1} step={0.01} onChange={(value)=>setPositionValue({...positionValue, topLeftX: valueToNumber(value)})}/>
            </Form.Item>
            <Form.Item label="Top Left (Y)" name="topY" rules={[{ required: true, message: 'Data is Required' }]}>
              <InputNumber min={0} max={1} step={0.01} onChange={(value)=>setPositionValue({...positionValue, topLeftY: valueToNumber(value)})}/>
            </Form.Item>
            <Form.Item label="Bottom Left (X)" name="bottomX" rules={[{ required: true, message: 'Data is Required' }]}>
              <InputNumber min={0} max={1} step={0.01} onChange={(value)=>setPositionValue({...positionValue, bottomRightX: valueToNumber(value)})}/>
            </Form.Item>
            <Form.Item label="Bottom Left (Y)" name="bottomY" rules={[{ required: true, message: 'Data is Required' }]}>
              <InputNumber min={0} max={1} step={0.01} onChange={(value)=>setPositionValue({...positionValue, bottomRightY: valueToNumber(value)})}/>
            </Form.Item>
          </Form>
          <Space size={"middle"}>
            <Space wrap>
              <Button onClick={()=>changeImage("prev")}>Prev</Button>
              <Button onClick={()=>changeImage("next")}>Next</Button>
            </Space>
            <Space wrap>
              <Button onClick={handleClear}>Clear</Button>
              <Button onClick={handleReset}>Reset</Button>
            </Space>
          </Space>
        </Col>
        <Col className="gutter-row" span={14}>
          {datasetData&&!loading?(
            <div style={{width:"750px",height: '620px',position:"relative"}} ref={imageRef}>
                <img 
                  src={`${imageUrlList?.[count]}`} 
                  alt="label" 
                  style={{visibility:"hidden",position:"absolute"}}
                  onLoad={(e) => {
                    setDisplayImageRef(e.target as HTMLImageElement);
                    setRenderImage(true)
                  }}
                />
              {displayImageRef&&renderImage?(
                <DatasetImageCropTools 
                    position={positionValue}
                    setPositionValue={setPositionValue}
                    imageUrl={`${imageUrlList?.[count]}`}
                    displayImageRef={displayImageRef}
                  />
              ):null}
              </div>
            ):<>Loading...</>}
        </Col>
      </Row> 
    </div>
  );
};

export default DatasetImageCrop;



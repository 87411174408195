import { offlineUrl } from "./../../urls/index";
import axios from "axios";
import { RootState } from "../index";
import { Dispatch } from "redux";
import * as Action from "../actions";
import { ActionTypes } from "../action-types/index";
import { CreateDatasetState } from "../reducers/createDataset";
import { setDatasetIdForInserting } from "./insertImagesCreators";

export const startCreateDataset = (): Action.startCreateDataset => ({
  type: ActionTypes.START_CREATE_DATASET,
});
export const cancelUpdateDataset = (): Action.cancelUpdateDataset => ({
  type: ActionTypes.CANCEL_UPDATE_DATASET,
});

export const clearCreateDatasetError = (): Action.clearCreateDatasetError => ({
  type: ActionTypes.CLEAR_CREATE_DATASET_ERROR,
});

export const handleDatasetOnChange = (
  key: keyof CreateDatasetState,
  value: string | string[]
): Action.handleDatasetOnChange => {
  if (key === "name" || key === "type") {
    return { type: ActionTypes.HANDLE_DATASET_ON_CHANGE, key, value: value };
  }
  return {
    type: ActionTypes.HANDLE_DATASET_ON_CHANGE,
    key: "classDescriptions",
    value: value,
  };
};

export const createDatasetAsync = () => async (
  dispatch: Dispatch<Action.CreateDatasetAction | Action.InsertImagesAction>,
  getState: () => RootState
) => {
  const { createDataset } = getState();
  const { name, type, classDescriptions } = createDataset;
  const jwt = localStorage.getItem("jwt");
  dispatch({
    type: ActionTypes.CREATE_DATASET_START,
  });
  try {
    const { data } = await axios.post(
      `${offlineUrl}/datasets`,
      {
        name: name.trim(),
        type,
        classDescriptions:
          type === "DETECT"
            ? undefined
            : classDescriptions.map((s) => s.trim()).filter((t) => t),
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
    dispatch({
      type: ActionTypes.CREATE_DATASET_SUCCESS,
      success: true,
    });
    dispatch(setDatasetIdForInserting(data.id));
  } catch (err:any) {
    dispatch({
      type: ActionTypes.CREATE_DATASET_ERROR,
      error: err.response.data.message,
    });
  }
};

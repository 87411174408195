import { Avatar, Menu, Dropdown } from "antd";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";

export interface AvatarLogoProps {}

const AvatarLogo: React.FC<AvatarLogoProps> = () => {
  const { currentUser } = useTypedSelector((state) => state.auth);
  const { logout } = useActions();

  const menu = (
    <Menu>
      <Menu.Item onClick={logout}>Logout @ {currentUser}</Menu.Item>
    </Menu>
  );
  return (
    <>
      <Dropdown overlay={menu} placement="topRight" arrow>
        <Avatar
          style={{
            verticalAlign: "middle",
            margin: "0 1rem",
            color: "#f56a00",
            backgroundColor: "#fde3cf",
          }}
          size="large"
        >
          {currentUser[0].toUpperCase()}
        </Avatar>
      </Dropdown>
    </>
  );
};

export default AvatarLogo;

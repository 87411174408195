import { Button, Checkbox, Input, message, Modal, Popover } from 'antd';
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react'
import { FixedSizeGrid } from 'react-window';
import { offlineUrlExpress } from '../../urls';
import { ConditionType, DatasetDetail } from '../TestResult-Virtual-Display';
import VirtualImageComp, { ResultType } from "../TestResult-Virtual-Image";



// export interface DatasetDetail {
//     image_url: string;
//     result: ResultType[]|null;
//     image_name:string;
//     predict: string | null;
//     isLabel:boolean|null;
// };

const TaskResultExportModal:React.FC<{
    imageData: DatasetDetail[],
    show: boolean, taskId: string,
    condition: ConditionType,
    detail:any,
    handleClose: () => void
}> = ({
    imageData,
    show,
    taskId,
    condition,
    detail,
    handleClose,
}) => {
    const { scoreRange } = condition;
    
    const displayImages = useMemo(() =>
        imageData.filter(data=> data.result&&data.result.length!==0 ), [imageData]);
    

    const len = displayImages.length;

    const [checkList, setCheckList]
        = useState<string[]>([]);
    
    const [newName, setNewName] = useState<string>("");

    const [open, setOpen] = useState<boolean>(false);

    
    const handleExport = async (newName?: string) => {
        
        if (!checkList[0]) return message.error("Please select at least 1 image for export.");

        const datasetImage = displayImages.filter(
            ele => checkList.find(name => name === ele.image_name
        )).map(data => {
            const { image_name: name, result } = data;
            const boxRegion = result!.filter(item =>
                item.score > scoreRange[0] && item.score < scoreRange[1] 
            ).map((ele: ResultType) => {
                
                const [tx, ty, bx, by] = ele.bbox;
                return {
                    "topLeftX": tx,
                    "topLeftY": ty,
                    "bottomRightX": bx,
                    "bottomRightY": by,
                    "class": ele.class
                }
            });
            return {
                filename: name,
                regions: [
                    ...boxRegion
                ]
            };
        });
        if (detail) {
            let classes = JSON.parse(detail.classes);
            const datasets = datasetImage.filter(ele=>ele.regions.length>0).map(data => {
                let area = data.regions;
                return {
                    ...data,
                    regions: area.map(item => {
                        return {
                            ...item,
                            class:classes[item.class]
                        }
                    })
                }
            });
            if (datasets.length > 1000) { 
                message.error("圖片不得超過1000張");
                return;
            };
            const dataset = {
                sourcedatasetname: detail.datasetname,
                type: detail.type,
                cropregion:detail.cropRegion,
                classifications: classes,
                datasetimage:datasets
            };
            const bodyData = newName ? {
                ...dataset,
                newdatasetname: newName.trim()
            } : {
                ...dataset,
                originaldatasetname: detail.datasetname,
            };
            try {
                // console.log("this",bodyData)
                let res = await axios.post(`${offlineUrlExpress}/api/importlabelsfromresult`, bodyData );
                message.success(res.data.message);
                handleClose();
            } catch (err: any) {
                message.error(err.message)
            }
         };
        
        return;
    };
    const handleClickCheckBox = (image_id: string) => {
        const target = checkList.find(id => id === image_id);
        if (!target) {
            setCheckList(prev => [...prev, image_id]);
        } else {
            setCheckList(prev => prev.filter(id => id !== image_id));
        };
    };

    const handleClickAll = () => { 
        if (imageData.length === checkList.length) {
            setCheckList([]);
            return;
        };
        const target: string[] = imageData.map(ele => ele.image_name);
        setCheckList([...target]);
        return;
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setNewName(e.target.value);
    };
    
    useEffect(() => {
        if (detail) {
            setNewName(detail.datasetname+"_new");
        };
        setCheckList([]);
    }, [show]);
    
    const handleNew = (newName:string) => { 
        if (!newName) {
            message.error("please enter new dataset name.");
            return;
        };
  
        return handleExport(newName);
    };
     const handleOpenChange = (newOpen: boolean) => {
        setOpen(newOpen);
    };
    const content = (
    <div>
        <Input placeholder={""} onChange={onChange} value={newName} />
        <Button  type="primary" onClick={()=>handleNew(newName)} style={{margin:'10px 0 0 70%'}}>Submit</Button>
    </div>
    );
  return (
    <Modal
            title="Export to Dataset"
            visible={show}
            // onOk={handleExport}
            okText={'Export'}
            onCancel={handleClose}
          width={1200}
          footer={[
              <Button key="back-cancel" onClick={handleClose}>
                    Cancel
                </Button>,
              <Popover
                  content={content}
                  title="* New Dataset *"
                  trigger="click"
              >
                    <Button type="primary" key="new">Export to New Dataset</Button>
                </Popover>,
              <Button
                  key="submit-type"
                  type="primary"
        
                    onClick={()=>handleExport() }
                >
                    Export to Origin Dataset
                </Button>,
                ]}
                >
            <div style={{ position: 'relative' }}>
                <Checkbox checked={checkList.length===imageData.length}  onChange={handleClickAll}>
                   {"Select All"}
                </Checkbox>
                <FixedSizeGrid
                    style={{ margin: "auto" }}
                    height={600}
                    rowCount={imageData.length % 3 === 0 ?
                        imageData.length / 3 : imageData.length / 3 + 1
                    }
                    columnWidth={320}
                    columnCount={3}
                    rowHeight={280}
                    width={1000}
                    itemData={displayImages}
                >
                    {({ data, columnIndex, rowIndex, style }) => {
                        const index = 3 * rowIndex + columnIndex;
                        if (!data[index]) return <></>;
                        const { image_name: name, image_url: url, result } = data[3 * rowIndex + columnIndex];

                        return (
                            <div style={{ ...style }} key={"image-check-"+index}>
                                <Checkbox
                                    key={"check-"+index}
                                    checked={Boolean(checkList.find(ele => ele === name))}
                                    onChange={() => handleClickCheckBox(name)}
                                >
                                    {`(${index+1}/${len})  ${name}`}
                                </Checkbox>
                                <VirtualImageComp
                                    url={`${offlineUrlExpress}/logs/${taskId}/${name}`}
                                    results={result}
                                    condition={condition} width={280}
                                    />
                            </div>
                        )
                    }
                    }
                </FixedSizeGrid>
            </div>
        </Modal>
  )
}

export default TaskResultExportModal
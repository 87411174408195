import styled from "@emotion/styled";
import { Button, Badge } from "antd";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import LabelImageDisplayer from "../Label-Image-Displayer";

const GlobalControlPannelContainer = styled.div`
  flex: 1;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid #f0f0f0;
  padding-right: 1.5rem;
`;

export interface OfflineControlPannelProps { }

const OfflineControlPannel: React.FC<OfflineControlPannelProps> = () => {
  const { onGoingDatasetId } = useTypedSelector((state) => state.onGoinglabel);
  const { toggleLabelingModel, toggleLabelingRefresh } = useActions();

    return (
      <GlobalControlPannelContainer>
        {onGoingDatasetId && (
          <Badge dot>
            <Button onClick={toggleLabelingModel} danger>
              Open Labeling
            </Button>
          </Badge>
        )}
        {onGoingDatasetId && (
          <LabelImageDisplayer onGoingDatasetId={onGoingDatasetId} />
        )}
      </GlobalControlPannelContainer>
    );
  };

  export default OfflineControlPannel;

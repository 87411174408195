import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  HashRouter,
} from "react-router-dom";
import AuthPage from "./pages/Auth";
import { useTypedSelector } from "./hooks/useTypedSelector";
import Layout from "./UI/Layout";
import OfflineHome from "./pages/Offline-Home/index";

export interface AppProps {}

const App: React.FC<AppProps> = () => {
  const { currentUser } = useTypedSelector((state) => state.auth);

  if (!currentUser) {
    return (
      <Router>
        <Switch>
          <Route path="/auth">
            <AuthPage />
          </Route>
          <Route path="/">
            <Redirect to="/auth" />
          </Route>
        </Switch>
      </Router>
    );
  }
  return (
    <HashRouter>
      <Switch>
        <Layout>
          <Route path="/home">
            <OfflineHome />
          </Route>
          <Route path="/online-predict">
            <h1>hello2</h1>
          </Route>
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
        </Layout>
      </Switch>
    </HashRouter>
  );
};

export default App;

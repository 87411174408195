import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Checkbox,
  message,
  Space,
  Table, Tag, Popconfirm,Modal
} from 'antd';
import React, { useRef, useState,useEffect } from 'react'
import PageTitle from "../../components/Page-Title/index";
import axios from "axios";
import IframeResizer from 'iframe-resizer-react'
import { offlineUrlExpress } from "../../urls";
import styled from "@emotion/styled";
import moment from 'moment';

export interface ServersContainerProps {}

const MyTag = styled(Tag)`
  margin-bottom: 2px;
  :hover {
    color: darkblue;
  }
`;

const ServersContainer: React.SFC<ServersContainerProps> = () => {

  const [loading, setLoading] = useState(false);
  const [hasData, sethasData] = useState(false);
  const [dataset, setDataset] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isParamsModalVisible, setIsParamsModalVisible] = useState(false);
  const [isAddNewServerModalVisible, setAddNewServerModalVisible] = useState(false);
  const [isEditServerModalVisible, setEditServerModalVisible] = useState(false);
  const [getSelectRecord, setSelectRecord] = useState({});
  const [getParams, setParams] = useState('');

  const [getResultUrl, setResultUrl] = useState("");

  const columns = [
    /*
    {
      title: 'Model ID',
      dataIndex: 'modelid',
    },
    {
      title: 'Parent Model ID',
      dataIndex: 'parentmodelid'
    },
    */
    {
      title: 'Model Name',
      dataIndex: 'servername',
      sorter: (a:any, b:any)  => a.servername.localeCompare(b.servername)
    }, 
    {
      title: 'Type',
      dataIndex: 'serverurl',
      sorter: (a:any, b:any)  => a.serverurl.localeCompare(b.serverurl)   
    },
    {
      title: 'Create Time',
      dataIndex: 'create_at',
      render: (create_at: string) => {
        let sdate = moment(create_at).format("YYYY/MM/DD HH:mm:ss");
        return (
          <div>{sdate}</div>
        );
      },
      sorter: (a:any, b:any)  => a.create_at.localeCompare(b.create_at)   
    },   
    {
      title: 'Operation',
      width: 150,
      dataIndex: '',
      key: 'x',
      render: (record:any) => (
        <Space size="middle">
          <MyTag color="geekblue" onClick={() => handleEditServer(record)}>Edit</MyTag>          
          <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record)}>
          <MyTag color="magenta" >Delete</MyTag>
        </Popconfirm>         
        </Space>
      ),
        /*
      render: () => (
          <Space size="middle">
          <a>Detail</a>
          <a>Delete</a>
          </Space>
      )
      */
    }
  ];

  const handleDelete = async(sdata:any) => {
    //const dataSource = [...dataset];
    console.log(sdata);

    let deleteServerId = sdata.id;
    
    axios.post(`${offlineUrlExpress}/api/deleteserver/`+deleteServerId)
      .then(res => {
        console.log(res);
        console.log(res.data);
        sethasData(false);
        fetchGetServersList();
      })    
    //setDataset(dataSource.filter(item => item.modelid !== modelid));
  };

  const handleDetail = (sdata:any) => {
    //const dataSource = [...dataset];
    console.log(sdata);
    showModal(sdata);
    //setDataset(dataSource.filter(item => item.modelid !== modelid));
  };
  
  const handleParams = (sdata:any) => {
    console.log(sdata);
    setParams(JSON.stringify(sdata.params, null, 4));
    setIsParamsModalVisible(true);
  };

  const handleAddNewServer = () => {
    formAddNewServer.resetFields();
    setAddNewServerModalVisible(true);
  };

  const handleEditServer = (sdata:any) => {
    console.log(sdata);
    setSelectRecord(sdata);
    formEditServer.resetFields();
    let editdata = {'servername':sdata['servername'], 'serverurl' : sdata['serverurl']}
    formEditServer.setFieldsValue(editdata);
    setEditServerModalVisible(true);
  };

  const fetchGetServersList = async () => {
    //const jwt = localStorage.getItem("jwt");
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${offlineUrlExpress}/api/serverslist`
      );
      if (JSON.stringify(dataset) != JSON.stringify(data)) {
        console.log(dataset)
        console.log(data)
        setDataset(data);
        console.log("Update ServersList datatable !!!")
      }      
      setLoading(false);
      sethasData(true);
      //console.log(dataset);
    } catch (err:any)  {
      //message.error(err.message);
      setLoading(false);
      sethasData(true);
    }
  };

  const showModal = (sdata: any) => {
    console.log(sdata);
    let modelid = sdata.modelid;
    let fullmodelid = `${offlineUrlExpress}/logs/${modelid}.txt`;
    setResultUrl("ShowTrainStatus.html?taskidurl="+fullmodelid);
    setIsModalVisible(true);
  };
  
  const handleOk = () => {
    setIsModalVisible(false);
    setIsParamsModalVisible(false);

  };
  
  const handleCancel = () => {
    setIsModalVisible(false);
    setIsParamsModalVisible(false);
  }; 

  const [formAddNewServer] = Form.useForm();

  const handleAddNewServerOk = () => {
    formAddNewServer
    .validateFields()
    .then(values => {
      let recordData:any = getSelectRecord;
      console.log(values);
      message.success('Add New Server Success', 2.5)
      /*
      message
      .loading('Add New Server in progress..', 2.5)
      .then(() => message.success('Add New Server Success', 2.5))        

      axios.post(`${offlineUrlExpress}/api/testmodel/${id}`, JSON.stringify(postData), {headers: { 'Content-Type': 'application/json' }})
      .then(res => {
        history.push('/home/tasks')
      })
      */    
      formAddNewServer.resetFields();
      setAddNewServerModalVisible(false);
      //onCreate(values);
    })
    .catch(info => {
      console.log('Validate Failed:', info);
    });

    //history.push('/home/tasks')

  };
  
  const handleAddNewServerCancel = () => {
    formAddNewServer.resetFields();
    setAddNewServerModalVisible(false);
  };


  const [formEditServer] = Form.useForm();

  const handleEditServerOk = () => {
    formEditServer
    .validateFields()
    .then(values => {
      let recordData:any = getSelectRecord;
      console.log(values);
      message.success('Edit Server Success', 2.5)
      /*
      message
      .loading('Edit Server in progress..', 2.5)
      .then(() => message.success('Edit Server Success', 2.5))       

      axios.post(`${offlineUrlExpress}/api/testmodel/${id}`, JSON.stringify(postData), {headers: { 'Content-Type': 'application/json' }})
      .then(res => {
        history.push('/home/tasks')
      })
      */    
      formEditServer.resetFields();
      setEditServerModalVisible(false);
      //onCreate(values);
    })
    .catch(info => {
      console.log('Validate Failed:', info);
    });

    //history.push('/home/tasks')

  };
  
  const handleEditServerCancel = () => {
    formEditServer.resetFields();
    setEditServerModalVisible(false);
  };

  useEffect(() => {
    if (!hasData){
      fetchGetServersList();
    }

    /*
    const timer = window.setInterval(() => {
      fetchGetServersList();
    }, 3000);
    return () => {
      window.clearInterval(timer);
    };
    */

  });

  return (
    <>
      <PageTitle title="Predict Servers List"></PageTitle>
      <Space size="middle">
      {/* <MyTag color="geekblue" onClick={(e) => { handleAddTrain(); }}>Add Train Task</MyTag>
      <MyTag color="geekblue" onClick={(e) => { handleAddValid(); }}>Add Validate Task</MyTag> */}
      <MyTag color="geekblue" onClick={(e) => { handleAddNewServer(); }}>Add New Server</MyTag>
      <MyTag color="gold" onClick={(e) => { fetchGetServersList(); }}>Reload</MyTag>
      </Space>
        <Table
          columns={columns}
          dataSource={dataset}
          pagination={{ pageSize: 10 }}
          //scroll={{ y: 600 }}
        />
        <Modal title="Detail Results" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} width={1000}>
          <div>
          <IframeResizer
          log
          inPageLinks
          //forwardRef={ref}        
          //onMessage={onMessage}
          //onResized={onResized}        
          src={getResultUrl}
          width="100%"
          scrolling={true}
          height="700px"
          frameBorder="0"
          />
          </div>  
        </Modal>
        <Modal title="Params" visible={isParamsModalVisible} onOk={handleOk} onCancel={handleCancel} width={1000}>
          <div><pre>
            {getParams}
            </pre>
          </div>  
        </Modal>

      <Modal
      title="Add New Predict Server"
      visible={isAddNewServerModalVisible}
      onOk={handleAddNewServerOk}
      onCancel={handleAddNewServerCancel}
      width={1000}
      >
        <Form form={formAddNewServer}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          initialValues={{ servername:"", serverurl:"" }}
        >
        <Form.Item label="Predict Serer Name" name="servername" rules={[{ required: true, message: 'Predict Model Server Name is Required' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Predict Serer Url" name="serverurl" rules={[{ required: true, message: 'Predict Model Server Url is Required' }]}>
          <Input />
        </Form.Item>
        </Form>
      </Modal>

      <Modal
      title="Edit Predict Server"
      visible={isEditServerModalVisible}
      onOk={handleEditServerOk}
      onCancel={handleEditServerCancel}
      width={1000}
      >
        <Form form={formEditServer}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          initialValues={{ servername:"", serverurl:"" }}
        >
        <Form.Item label="Predict Serer Name" name="servername" rules={[{ required: true, message: 'Predict Model Server Name is Required' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Predict Serer Url" name="serverurl" rules={[{ required: true, message: 'Predict Model Server Url is Required' }]}>
          <Input />
        </Form.Item>
        </Form>
      </Modal>                             
    </>
    );
};

export default ServersContainer;

import styled from "@emotion/styled";

import React, {useEffect,useRef} from 'react';
import { useStore } from "react-redux";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import {  message, Popconfirm, Menu, Dropdown } from "antd";
import Button from "antd-button-color";
import { useHotkeys } from 'react-hotkeys-hook';

const LabelTagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;
const InfoSection = styled.div<{ extends?: boolean }>`
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  h3 {
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 10px;
  }
  flex-grow: ${(props) => (props.extends ? 1 : 0)};
  margin-bottom: 0.5rem;
`;
const InfoP = styled.p`
  text-align: center;
  word-wrap: break-word;
`;

export interface LabelImageControlPannelProps {

}


const LabelImageControlPannel: React.FC<LabelImageControlPannelProps> = () => {

  const {
    currentIndex,
    onGoingDatasetName,
    onGoingDatasetType,
    currentImage,
    currentLabelElements,
    onGoingClassifications,
    isLabelUpdating,
    currentLabelId,
  } = useTypedSelector((state) => state.onGoinglabel);

  const store = useStore();

  const {
    setCurrentLabelId,
    setCurrentHoverLabelElement, //popup class 選項
    deleteLabelAll,
    deleteLabelByIndex,
    insertDetectLabelsToImageByIdAsync,
    setCurrentLabelClassification,
    datasetImageNavigateTo,
  } = useActions();

  const onDeleteLabelConfirm = (index: number) => {
    deleteLabelByIndex(index);
  };
  // const onDeleteLabelCancel = () => {};
  const saveButtonRef:any = useRef(null);
  const removeAllLabelButtonRef:any = useRef(null);

  // const [btnId,setBtnId] = React.useState<number|null>(null)

  const getCurrentIndex = () => {
    let gstate = store.getState();
    return gstate.onGoinglabel.currentIndex;
  }

  const getImagesCount = () => {
    let gstate = store.getState();
    return gstate.onGoinglabel.imagesCount;
  }
  
  const getIsOnGoingLabelModelOpen = () => {
    let gstate = store.getState();
    return gstate.onGoinglabel.isOnGoingLabelModelOpen;
  }

  /*
  useHotkeys('z', ():any => {
    console.log("Test Hotkey");
    console.log(store.getState());
    
    //saveButtonRef.current.click();
  })

  useHotkeys('c', ():any => {
    console.log("Test Hotkey");
    console.log(getCurrentIndex());
    datasetImageNavigateTo(getCurrentIndex() + 1);
    //saveButtonRef.current.click();
  })
*/


useHotkeys('s', ():any => {
  if (getIsOnGoingLabelModelOpen()){
    console.log("Save Hotkey");
    saveButtonRef.current.click();
  }
})


useHotkeys('w', ():any => {
  if (getIsOnGoingLabelModelOpen()){
    console.log(currentLabelElements);
    console.log(onGoingClassifications);
    deleteLabelAll();
  }
  /*
  for (let i=0; i < currentLabelElements.length; i++){
    console.log(currentLabelElements[i].id,currentLabelElements[i].classificationId)
    deleteLabelByIndex(i);
  }
  */

  //saveButtonRef.current.click();
})


  useHotkeys('q', ():any => {
    if (getIsOnGoingLabelModelOpen()){
      console.log("Previous and Save Hotkey");
      saveButtonRef.current.click();
      const toIndex = getCurrentIndex() - 1;
      if (toIndex < 0) {
        message.error(`index can not be under 1`, 0.8);
      } else {
        datasetImageNavigateTo(toIndex);
      }
    }
  })

  useHotkeys('e', ():any => {
    if (getIsOnGoingLabelModelOpen()){
      console.log("Next and Save Hotkey");
      saveButtonRef.current.click();
      const toIndex = getCurrentIndex() + 1;
      if (toIndex >= getImagesCount()) {
        message.error(`index can not be bigger than total`, 0.8);
      } else {
        datasetImageNavigateTo(toIndex);
      }
    }    
  })

  const handleOnRemoveAllLabel = () => {
    deleteLabelAll();
  }

  const btnMouseEvent=(index:number,id:number|null)=>{
    setCurrentHoverLabelElement(index)
    setCurrentLabelId(id)
  }

  const handleOnSave = () => {
    if (onGoingDatasetType.includes("CLASSIFY")) {
      const hasNoclass = currentLabelElements.findIndex(
        (labelObj) => !labelObj.classificationId
      );
      /* Temp Remark
      if (hasNoclass >= 0) {
        return message.error("please select a classification",0.8);
      }
      */
    }

    insertDetectLabelsToImageByIdAsync(currentImage!.id, currentLabelElements);
  };
  return  (
    <Container>
      <InfoSection>
        <div>
          <h3>Dataset Type:</h3>
          <InfoP>{onGoingDatasetType}</InfoP>
        </div>
      </InfoSection>
      <InfoSection>
        <div>
          <h3>Dataset Name:</h3>
          <InfoP>{onGoingDatasetName}</InfoP>
        </div>
      </InfoSection>
      <InfoSection>
        <div>
          <h3>Image Name:</h3>
          <p style={{ wordWrap: "break-word" }}>{currentImage?.filename}</p>
        </div>
      </InfoSection>
      <InfoSection>
        <div>
          <h3>Image Source:</h3>
          <InfoP>{currentImage?.sourceOrigin}</InfoP>
        </div>
      </InfoSection>
      <InfoSection>
        <div>
          <h3>Is Labeled:</h3>
          <InfoP>{currentImage?.labeled ? "YES" : "NO"}</InfoP>
        </div>
      </InfoSection>
      <InfoSection
        extends={true}
        style={{ minHeight: "250px", overflow: "scroll" }}
      >
        <h3>Current Labels:</h3>
        <LabelTagsContainer>
          
          <>
          {currentLabelElements.map(({ id, classificationId }, index) => {
            
            const menu = (
              <Menu>
                {onGoingClassifications.map(({ id, description }) => (
                  <Menu.Item
                    key={id}
                    onClick={() => {setCurrentLabelClassification(index, id)}}
                  >
                    {description}
                  </Menu.Item>
                ))}
              </Menu>
            );
            if (onGoingDatasetType === "CLASSIFY" || onGoingDatasetType === "ABNORMAL") {
              return (
                <Dropdown overlay={menu} placement="topLeft" arrow>
                  <Button
                    type="primary"
                    size="small"
                    style={{ margin: "2px"}}
                    key={"label-classify" + id}
                    onMouseLeave={() => btnMouseEvent(-1,null)}
                    onMouseEnter={() => btnMouseEvent(index,id)}
                    ghost={id!==currentLabelId}
                  >
                    {classificationId
                      ? onGoingClassifications.find(
                          ({ id }) => id === classificationId
                        )?.description
                      : `Pick a Class`}
                  </Button>
                </Dropdown>
              );
            }
            if (onGoingDatasetType === "DETECT_AND_CLASSIFY") {
              return (
                <Popconfirm
                  title="Are you sure to delete this label?"
                  onConfirm={() => onDeleteLabelConfirm(index)}
                  // onCancel={onDeleteLabelCancel}
                  okText="Yes"
                  cancelText="No"
                  key={id}
                >
                  <Dropdown overlay={menu} placement="topLeft" arrow>
                    <Button
                      size="small"
                      type="primary"
                      // currentLabelId?"yellow":"default"
                      style={{ margin: "2px"}}
                      key={"label-classify" + id}
                      onMouseLeave={() => btnMouseEvent(-1,null)}
                      onMouseEnter={() => btnMouseEvent(index,id)}
                      ghost={id!==currentLabelId}
                    >
                      {classificationId
                        ? onGoingClassifications.find(
                            ({ id }) => id === classificationId
                          )?.description
                        : `Pick a Class`}
                    </Button>
                  </Dropdown>
                </Popconfirm>
              );
            }
            return (
              <Popconfirm
                title="Are you sure to delete this label?"
                onConfirm={() => onDeleteLabelConfirm(index)}
                // onCancel={onDeleteLabelCancel}
                okText="Yes"
                cancelText="No"
                key={id}
              >
                <Button
                  size="small"
                  type="primary"
                  style={{ margin: "2px" }}
                  onMouseLeave={() => btnMouseEvent(-1,null)}
                  onMouseEnter={() => btnMouseEvent(index,id)}
                  ghost={id!==currentLabelId}
                > 
                  {classificationId
                    ? onGoingClassifications.find(
                        ({ id }) => id === classificationId
                      )?.description
                    : `Label-${index + 1}`}
                </Button>
              </Popconfirm>
            );
          })}

          </>
        </LabelTagsContainer>
      </InfoSection>
      <Button 
        onClick={handleOnRemoveAllLabel}
        danger
        type="primary"
        shape="round"
        style={{ width: "100%" }}
        ref={removeAllLabelButtonRef}
      >Remove All Label [w]</Button>
      <br/>      
      <Button
        loading={isLabelUpdating}
        onClick={handleOnSave}
        type="primary"
        shape="round"
        style={{ width: "100%" }}
        ref = {saveButtonRef}
      >
        SAVE [s]
      </Button>
    </Container>
  );
};

export default LabelImageControlPannel;
//image right bar menu
import { ActionTypes } from "../action-types/index";
import * as Actions from "../actions";
import axios from "axios";
import { offlineUrl, offlineUrlExpress } from "../../urls/index";
import { message } from "antd";
import { Dispatch } from "redux";
import { isArray } from "lodash";
import { DatasetImageJson, LabelElement } from "../reducers/onGoinglabel";
import { Classification } from "../reducers/datasets";
import {ClassSummary} from "../reducers/onGoinglabel"


export const setCurrentLabelClassification = (
  labelIndex: number,
  classificationId: number
): Actions.setCurrentLabelClassification => ({
  type: ActionTypes.SET_CURRENT_LABEL_CLASSIFICATION,
  labelIndex,
  classificationId,
});
export const deleteLabelAll = ():
 Actions.deleteLabelAll => ({
  type: ActionTypes.DELETE_LABEL_ALL
});
export const deleteLabelByIndex = (
  index: number
): Actions.deleteLabelByIndex => ({
  type: ActionTypes.DELETE_LABEL_BY_INDEX,
  index: index,
});
export const setCurrentHoverLabelElement = (
  index: number
): Actions.setCurrentHoverLabelElement => ({
  type: ActionTypes.SET_CURRENT_HOVER_LABEL_ELEMENT,
  index: index,
});

export const setCurrentLabelElements = (
  labelElements: LabelElement[]
): Actions.setCurrentLabelElements => ({
  type: ActionTypes.SET_CURRENT_LABEL_ELEMENTS,
  labelElements: labelElements,
});

export const setCurrentLabelId = (
  labelId: number|null
): Actions.setCurrentLabelId => ({
  type: ActionTypes.SET_CURRENT_LABEL_ID,
  labelId:  labelId
});

export const setCurrentDatasetId = (
  DatasetType: number|null
): Actions.setCurrentDatasetId => ({
  type: ActionTypes.SET_CURRENT_DATASET_ID,
  DatasetType:  DatasetType
});

export const setLabelSummary=(
  labelValue:[]
): Actions.setLabelSummary=>({
  type: ActionTypes.SET_LABEL_SUMMARY,
  labelSummary: labelValue
});

// export const setClassSummary= (
//   classValue:string
//   ):Actions.setClassSummary =>({
//     type: ActionTypes.SET_CLASS_SUMMARY,
//     classValue: classValue
// });

export const setClassSummary =(url:string)=>async(
  dispatch: Dispatch
)=>{
  try{
    const resclass = await axios.get(url);
    const jData = await resclass.data;
    console.log(jData)
    dispatch({ 
      type:ActionTypes.SET_CLASS_SUMMARY,
      classSummary: jData 
    })
   }catch(err:any){
    alert(err.message);
  }
};

export const setAutoClassLabel =(url:string)=>async(
  dispatch: Dispatch
)=>{
  try{
    const autoclass = await axios.get(url);
    const jData = await autoclass.data;
    console.log(jData)
    if (jData?.result === true) {
      alert(`${jData?.count} images Successfully Labeled`);
    } else {
      alert(jData?.message);
    }
   }catch(err:any){
    alert(err.message);
  }
};

export const initClassSummary=(): Actions.initClassSummary=>({
  type: ActionTypes.INIT_CLASS_SUMMARY
});
export const initLabelSummary=(): Actions.initLabelSummary=>({
  type: ActionTypes.INIT_LABEL_SUMMARY
});

export const datasetImageNavigateTo = (
  index: number
): Actions.datasetImageNavigateTo => ({
  type: ActionTypes.DATASET_IMAGE_NAVIGATE_TO,
  index: index,
});


export const setOnGoingLabelDataset = (
  datasetId: number,
  datasetType: string,
  name: string,
  classifications: Classification[],
  imagesCount?: number,
): Actions.setOnGoingLabelDataset => ({
  type: ActionTypes.SET_ON_GOING_LABAL_DATASET,
  datasetId: datasetId,
  datasetType: datasetType,
  datasetName: name,
  classifications: classifications,
  imagesCount:imagesCount,
});

export const setOnGoingLabelDatasetNavigateTo = (
  datasetId: number,
  datasetType: string,
  name: string,
  classifications: Classification[],
  index: number,
): Actions.setOnGoingLabelDatasetNavigateTo => ({
  type: ActionTypes.SET_ON_GOING_LABAL_DATASET_NAVIGATE_TO,
  datasetId: datasetId,
  datasetType: datasetType,
  datasetName: name,
  classifications: classifications,
  index: index,
});

export const toggleLabelingRefresh = (): Actions.toggleLabelingRefresh => ({
  type: ActionTypes.TOGGLE_LABELING_REFRESH,
});

export const toggleLabelingModel = (): Actions.toggleLabelingModel => ({
  type: ActionTypes.TOGGLE_LABELING_MODEL,
});

export const datasetImagesQueryingAsync =
  (datasetId: number, labeled: boolean | undefined, classed?:string,isImport?:boolean, source?:string) =>
  async (dispatch: Dispatch<Actions.OnGoingLabelAction>) => {
    dispatch({
      type: ActionTypes.DATASET_IMAGES_QUERYING_START,
    });
    const jwt = localStorage.getItem("jwt");

    try {
      const { data } = await axios.get<number>(

        `${offlineUrlExpress}/api/dataset-images/${datasetId}/count`,
        {
          params: {
            labeled: labeled,
            class: classed,
            isimport: isImport,
            source: source
          },
        }

        /*
        `${offlineUrl}/dataset-images/${datasetId}/count`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
          params: {
            labeled: labeled,
          },
        }
        */
      );
      dispatch({
        type: ActionTypes.DATASET_IMAGES_QUERYING_SUCCESS,
        imagesCount: data,
      });
    } catch (err:any) {
      let errMsg = [];
      if (!isArray(err.response.data.message)) {
        errMsg.push(err.response.data.message);
      }
      errMsg.forEach((errMsg: string) => message.error(errMsg));
    }
  };

export const datasetImageFetchingAsync =
  (datasetId: number, currentIndex: number, labeled?: boolean,classed?:string,isImport?:boolean,source?:string) =>
  async (dispatch: Dispatch<Actions.OnGoingLabelAction>) => {
    dispatch({
      type: ActionTypes.DATASET_IMAGE_FETCHING_START,
    });
    const jwt = localStorage.getItem("jwt");

    try {

      const { data } = await axios.get<DatasetImageJson[]>(
        `${offlineUrlExpress}/api/dataset-images/${datasetId}`,
        {
          params: {
            skip: currentIndex,
            take: 1,
            labeled: labeled,
            class: classed,
            isimport: isImport,
            source: source
          },
        }
      );      
            /*
      const { data } = await axios.get<DatasetImageJson[]>(
        `${offlineUrl}/dataset-images/${datasetId}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
          params: {
            skip: currentIndex,
            take: 1,
            labeled: labeled,
            class:classed
          },
        }
      );
      */
      //refetch count

      if (data.length < 1) {
        message.error("no result");
        return dispatch({
          type: ActionTypes.DATASET_IMAGE_FETCHING_ERROR,
        });
      }
      dispatch({
        type: ActionTypes.DATASET_IMAGE_FETCHING_SUCCESS,
        currentImage: data[0],
      });
      dispatch(setCurrentLabelElements(data[0].datasetDetectLabels!));
    } catch (err:any) {
      let errMsg = [];
      if (!isArray(err.response.data.message)) {
        errMsg.push(err.response.data.message);
      }
      errMsg.forEach((errMsg: string) => message.error(errMsg));
      dispatch({
        type: ActionTypes.DATASET_IMAGE_FETCHING_ERROR,
      });
    }
  };

export const insertDetectLabelsToImageByIdAsync =
  (datasetImageId: number, labels: LabelElement[]) =>
  async (dispatch: Dispatch<Actions.OnGoingLabelAction>) => {
    dispatch({
      type: ActionTypes.INSERT_DETECT_LABELS_TO_IMAGE_START,
    });
    message.info("Start Updating Labels", 0.2);
    const jwt = localStorage.getItem("jwt");

    for (let i=0;i<labels.length;i++){
      labels[i].isImport = false;
    }

    try {
      /*
      await axios.post(
        `${offlineUrl}/dataset-images/${datasetImageId}/detect-labels`,
        {
          coordinates: labels,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      */
      await axios.post(
        `${offlineUrlExpress}/api/dataset-images/detect-labels/${datasetImageId}`,
        {
          coordinates: labels,
        }
      );
      dispatch({
        type: ActionTypes.INSERT_DETECT_LABELS_TO_IMAGE_SUCESS,
        labelCount: labels.length,
      });
      message.success("Labels Inserted Success!", 0.5);
    } catch (err:any) {
      let errMsg = [];
      if (!isArray(err.response.data.message)) {
        errMsg.push(err.response.data.message);
      }
      errMsg.forEach((errMsg: string) => message.error(errMsg));
      dispatch({
        type: ActionTypes.INSERT_DETECT_LABELS_TO_IMAGE_FAIL,
      });
    }
  };

export const deleteDatasetImageByIdAsync =
  (datasetImageId: number) =>
  async (dispatch: Dispatch<Actions.OnGoingLabelAction>) => {
    dispatch({
      type: ActionTypes.DELETE_DATASET_IMAGE_BY_ID_START,
    });
    const jwt = localStorage.getItem("jwt");

    try {
      const { data } = await axios.delete(
        `${offlineUrlExpress}/api/dataset-images/${datasetImageId}`,
        /*
        `${offlineUrl}/dataset-images/${datasetImageId}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
        */
      );
      if (data) {
        message.success("image deleted!", 0.5);

        dispatch({
          type: ActionTypes.DELETE_DATASET_IMAGE_BY_ID_SUCCESS,
        });
      } else {
        message.error("delete failed");
        dispatch({
          type: ActionTypes.DELETE_DATASET_IMAGE_BY_ID_FAIL,
        });
      }
    } catch (err:any) {
      let errMsg = [];
      if (!isArray(err.response.data.message)) {
        errMsg.push(err.response.data.message);
      }
      errMsg.forEach((errMsg: string) => message.error(errMsg));
      dispatch({
        type: ActionTypes.DELETE_DATASET_IMAGE_BY_ID_FAIL,
      });
    }
  };


import { useState, useEffect } from "react";
import axios from "axios";
import { offlineUrl,offlineUrlExpress } from "../../urls/index";
import { Card, message, Tag, Typography, Space } from "antd";
import styled from "@emotion/styled";
const { Title, Text } = Typography;

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 5px;
`;
const InnerDiv = styled.div``;

export interface DatasetResponse {
  id: number;
  name: string;
  type: string;
  imagesCount: number;
  classifications: { id: number; description: string }[];
  //cropRegion: {topLeftX: number; topLeftY: number; bottomRightX: number; bottomRightY: number;}
  //hasLabeled: number;
}

export interface DatasetCardProps {
  datasetId: number;
}

const DatasetCard: React.FC<DatasetCardProps> = ({ datasetId }) => {
  const [loading, setLoading] = useState(false);
  const [dataset, setDataset] = useState<DatasetResponse | null>(null);

  useEffect(() => {
    const fetchGetDatasetById = async () => {
      const jwt = localStorage.getItem("jwt");
      try {
        setLoading(true);
        const { data } = await axios.get<DatasetResponse>(
          `${offlineUrlExpress}/api/datasets/${datasetId}`
        );        
        /*
        const { data } = await axios.get<DatasetResponse>(
          `${offlineUrl}/datasets/${datasetId}`,
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          }
        );
        */
        setDataset(data);
        setLoading(false);
      } catch (err:any) {
        message.error(err.message);
        setLoading(false);
      }
    };
    if (datasetId) {
      fetchGetDatasetById();
    }
  }, [datasetId]);
  return (
    <div style={{ width: "100%" }}>
      <Card style={{ width: "100%" }} loading={loading}>
        <Title level={5}>Dataset Info</Title>
        <InnerContainer>
          <InnerDiv style={{ width: "50%" }}>
            <Text style={{ fontWeight: "bold", marginRight: "5px" }}>
              type:
            </Text>
            <Text>{dataset?.type}</Text>
          </InnerDiv>
          <InnerDiv style={{ width: "50%" }}>
            <Text style={{ fontWeight: "bold", marginRight: "5px" }}>
              current images:
            </Text>
            <Text>{dataset?.imagesCount}</Text>
          </InnerDiv>
        </InnerContainer>
        <InnerContainer>
          <InnerDiv>
            <Text style={{ fontWeight: "bold", marginRight: "5px" }}>
              name:
            </Text>
            <Text>{dataset?.name}</Text>
          </InnerDiv>
        </InnerContainer>
        {dataset?.type !== "DETECT" && (
          <Space size="small" direction="vertical">
            <Text style={{ fontWeight: "bold" }}>Classifications:</Text>
            <TagsContainer>
              {dataset?.classifications.map(({ id, description }, i) => {
                let color = i % 2 === 0 ? "geekblue" : "green";
                return (
                  <Tag style={{ marginBottom: "2px" }} key={id} color={color}>
                    {description}
                  </Tag>
                );
              })}
            </TagsContainer>
          </Space>
        )}
      </Card>
    </div>
  );
};

export default DatasetCard;

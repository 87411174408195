import { Form, Input, Modal } from "antd";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import { TableClassificationEditTask } from "../../states/reducers/tableClassifications";
import { useEffect } from "react";
export interface ClassificationEditRenameProps {
  editTask: TableClassificationEditTask;
}
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const ClassificationEditRename: React.FC<ClassificationEditRenameProps> = ({
  editTask,
}) => {
  const [form] = Form.useForm();
  const {
    setClassificationEditClose,
    renameClassificationAsync,
    fetchDatasetsAsync,
  } = useActions();
  useEffect(() => {
    return () => {
      fetchDatasetsAsync();
    };
  }, [fetchDatasetsAsync]);

  const { targetClassification, isClassificationUpdating } = useTypedSelector(
    (state) => state.classifications
  );
  const onFinish = () => {
    const { description } = form.getFieldsValue();
    renameClassificationAsync(targetClassification!.id, description);
  };
  return (
    <Modal
      title="Rename This Classification"
      confirmLoading={isClassificationUpdating}
      onCancel={setClassificationEditClose}
      onOk={onFinish}
      visible={editTask !== TableClassificationEditTask.NONE}
    >
      <Form
        {...layout}
        form={form}
        name="basic"
        initialValues={{ description: targetClassification?.description }}
      >
        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
              message: "Please input a new description for this class!",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ClassificationEditRename;

import * as Action from "../actions";
import axios from "axios";
import { Dispatch } from "redux";
import { ActionTypes } from "../action-types/index";
import { offlineUrl,offlineUrlExpress } from "../../urls";
import { SourceFolder, SourceImagesQueryGroup, SourceImagesQueryFilterGroup } from "../reducers/insertImages";
import { Moment } from "moment";
import { RootState } from "../reducers/index";
import * as _ from "lodash";
export const setDatasetIdForInserting = (
  datasetId: number
): Action.setDatasetIdForInserting => ({
  type: ActionTypes.SET_DATASET_ID_FOR_INSERTING,
  datasetId: datasetId,
});

export const cancelImagesInserting = (): Action.cancelImagesInserting => ({
  type: ActionTypes.CANCEL_IMAGES_INSERTING,
});

export const addImagesToGroupAsync = (
  folderId: number,
  search: string | undefined,
  minDate: Moment | undefined,
  maxDate: Moment | undefined
) => async (dispatch: Dispatch<Action.InsertImagesAction>) => {
  dispatch({
    type: ActionTypes.ADD_IMAGES_TO_GROUP_START,
  });
  const jwt = localStorage.getItem("jwt");
  try {
    const { data } = await axios.get<SourceImagesQueryGroup>(
      `${offlineUrl}/source-folders/${folderId}`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
        params: {
          search: search && search.trim().length ? search.trim() : undefined,
          minDate: minDate ? minDate.format() : undefined,
          maxDate: maxDate ? maxDate.format() : undefined,
        },
      }
    );
    if (minDate) {
      data["minDate"] = minDate.format();
    }
    if (maxDate) {
      data["maxDate"] = maxDate.format();
    }
    if (search && search.trim().length) {
      data["search"] = search.trim();
    }
    if (data.count <= 0) {
      dispatch({
        type: ActionTypes.ADD_IMAGES_TO_GROUP_ERROR,
        error: ["combination has no result"],
      });
      return setTimeout(() => {
        dispatch({
          type: ActionTypes.CLEAR_ADD_IMAGES_ERROR,
        });
      });
    }
    dispatch({
      type: ActionTypes.ADD_IMAGES_TO_GROUP_SUCCESS,
      group: data,
    });
    return setTimeout(() => {
      dispatch({
        type: ActionTypes.CLEAR_ADD_IMAGES_SUCCESS,
      });
    });
  } catch (err:any)  {
    dispatch({
      type: ActionTypes.ADD_IMAGES_TO_GROUP_ERROR,
      error: err.response.data.message,
    });
    setTimeout(() => {
      dispatch({
        type: ActionTypes.CLEAR_ADD_IMAGES_ERROR,
      });
    }, 3000);
  }
};


export const addImagesToGroupFilterAsync = (
  folderId: number,
  filter: Array<string> | undefined,
  minDate: Moment | undefined,
  maxDate: Moment | undefined
) => async (dispatch: Dispatch<Action.InsertImagesAction>) => {
  dispatch({
    type: ActionTypes.ADD_IMAGES_TO_GROUP_START,
  });
  const jwt = localStorage.getItem("jwt");
  try {
    
    let postData = {
      filter: filter && filter.length ? filter : undefined,
      minDate: minDate ? minDate.format() : undefined,
      maxDate: maxDate ? maxDate.format() : undefined,
    };

    const { data } = await axios.post<SourceImagesQueryFilterGroup>(
      `${offlineUrlExpress}/api/dataset-add-image/${folderId}`,
      JSON.stringify(postData), { headers: { 'Content-Type': 'application/json'} }
    );
    if (minDate) {
      data["minDate"] = minDate.format();
    }
    if (maxDate) {
      data["maxDate"] = maxDate.format();
    }
    if (filter && filter.length) {
      data["search"] = filter.join(',');
    }
    if (data.count <= 0) {
      dispatch({
        type: ActionTypes.ADD_IMAGES_TO_GROUP_ERROR,
        error: ["combination has no result"],
      });
      return setTimeout(() => {
        dispatch({
          type: ActionTypes.CLEAR_ADD_IMAGES_ERROR,
        });
      });
    }
    dispatch({
      type: ActionTypes.ADD_IMAGES_TO_GROUP_SUCCESS,
      group: data,
    });
    return setTimeout(() => {
      dispatch({
        type: ActionTypes.CLEAR_ADD_IMAGES_SUCCESS,
      });
    });
  } catch (err:any)  {
    dispatch({
      type: ActionTypes.ADD_IMAGES_TO_GROUP_ERROR,
      error: err.response.data.message,
    });
    setTimeout(() => {
      dispatch({
        type: ActionTypes.CLEAR_ADD_IMAGES_ERROR,
      });
    }, 3000);
  }
};

export const fetchSourceFoldersAsync = () => async (
  dispatch: Dispatch<Action.InsertImagesAction>
) => {
  dispatch({
    type: ActionTypes.FETCH_SOURCE_FOLDER_START,
  });
  const jwt = localStorage.getItem("jwt");
  try {
    const { data } = await axios.get<SourceFolder[]>(
      `${offlineUrl}/source-folders`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
    dispatch({
      type: ActionTypes.FETCH_SOURCE_FOLDER_SUCCESS,
      sourceFolder: data,
    });
  } catch (err:any)  {
    dispatch({
      type: ActionTypes.FETCH_SOURCE_FOLDER_ERROR,
      error: err.response.data.message,
    });
    setTimeout(() => {
      dispatch({
        type: ActionTypes.CLEAR_ADD_IMAGES_ERROR,
      });
    }, 3000);
  }
};


export const fetchSourceFoldersFilterAsync = (
  minFolderDate: Moment | undefined,
  maxFolderDate: Moment | undefined
) => async (
  dispatch: Dispatch<Action.InsertImagesAction>
) => {
  dispatch({
    type: ActionTypes.FETCH_SOURCE_FOLDER_START,
  });
  const jwt = localStorage.getItem("jwt");
  try {
    let postData = {
      minFolderDate: minFolderDate ? minFolderDate.format() : undefined,
      maxFolderDate: maxFolderDate ? maxFolderDate.format() : undefined,
    };

    const { data } = await axios.post<SourceFolder[]>(
      `${offlineUrlExpress}/api/source-folders-filter/`,
      JSON.stringify(postData), { headers: { 'Content-Type': 'application/json'} }
    );
    /*    
    const { data } = await axios.get<SourceFolder[]>(
      `${offlineUrl}/source-folders`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
    */
    dispatch({
      type: ActionTypes.FETCH_SOURCE_FOLDER_SUCCESS,
      sourceFolder: data,
    });
  } catch (err:any)  {
    dispatch({
      type: ActionTypes.FETCH_SOURCE_FOLDER_ERROR,
      error: err.response.data.message,
    });
    setTimeout(() => {
      dispatch({
        type: ActionTypes.CLEAR_ADD_IMAGES_ERROR,
      });
    }, 3000);
  }
};

export const insertingImagesAsync = () => async (
  dispatch: Dispatch<Action.InsertImagesAction>,
  getState: () => RootState
) => {
  dispatch({
    type: ActionTypes.INSERTING_IMAGES_START,
  });
  const jwt = localStorage.getItem("jwt");
  const { insertImages } = getState();
  const { datasetId, imagesQueryGroup } = insertImages;
  const toAdd = _.map(imagesQueryGroup, ({ imageIds }) => imageIds).flat();
  const toAddUnique = new Set([...toAdd]);
  try {
    const { data } = await axios.patch<{ imagesCount: number }>(
      `${offlineUrl}/datasets/${datasetId}/add-images`,
      {
        imageIds: Array.from(toAddUnique),
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
    dispatch({
      type: ActionTypes.INSERTING_IMAGES_SUCCESS,
      updateCount: data.imagesCount,
    });
  } catch (err:any)  {
    dispatch({
      type: ActionTypes.INSERTING_IMAGES_ERROR,
      error: err.response.data.message,
    });
    setTimeout(() => {
      dispatch({
        type: ActionTypes.CLEAR_ADD_IMAGES_ERROR,
      });
    }, 3000);
  }
};

export const deleteSourceByIndex = (index: number) => ({
  type: ActionTypes.DELETE_SOURCE_BY_INDEX,
  index: index,
});

// const jwt = localStorage.getItem("jwt");
// axios
//   .get(`${offlineUrl}/source-folders/${targetFolderId}`, {
//     headers: {
//       Authorization: `Bearer ${jwt}`,
//     },
//     params: {
//       search: search ? search : undefined,
//       minDate: minDate?.format(),
//       maxDate: maxDate?.format(),
//     },
//   })
//   .then(({ data }) => console.log(data))
//   .catch((err) => console.log(err));

import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import { useEffect } from "react";
import styled from "@emotion/styled";
import DatasetsTable from "../../components/Datasets-Table/index";
import PageTitle from "../../components/Page-Title/index";
import DatasetsControlPannel from "../../components/Datasets-Control-pannel/index";
import { message } from "antd";
import { FilterByOwn, FilterByType } from "../../states/reducers/datasets";
import DatasetTableClassificationTableEditRename from "../../components/Dataset-Table-Classification-Edit-Rename-Form";
import DatasetTableClassificationTableEditDelete from "../../components/Dataset-Table-Classification-Edit-Delete-Form";
import TableClassificationEditAdd from "../../components/Dataset-Table-Classification-Edit-Add-Form";

import { TableClassificationEditTask } from "../../states/reducers/tableClassifications";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export interface DatasetsContainerProps {}

const DatasetsContainer: React.FC<DatasetsContainerProps> = () => {
  const { currentUser } = useTypedSelector((state) => state.auth);

  const { datasets, isDatasetPending, error, filterByOwn, filterByType } =
    useTypedSelector((state) => state.datasets);
  const { fetchDatasetsAsync, clearFetchDatasetsError } = useActions();
  const { editTask } = useTypedSelector((state) => state.classifications);
  useEffect(() => {
    if (error.length) {
      error.forEach((err) => message.error(err, 3));
      clearFetchDatasetsError();
    }
  }, [error, clearFetchDatasetsError]);
  useEffect(() => {
    fetchDatasetsAsync();
  }, [fetchDatasetsAsync]);

  let filteredDataset = datasets;
  switch (filterByOwn) {
    case FilterByOwn.USER:
      filteredDataset = filteredDataset.filter(
        ({ user }) => user === currentUser
      );
      break;
    case FilterByOwn.OTHERS:
      filteredDataset = filteredDataset.filter(
        ({ user }) => user !== currentUser
      );
      break;
    case FilterByOwn.ALL:
    default:
      break;
  }
  switch (filterByType) {
    case FilterByType.DETECT:
    case FilterByType.CLASSIFY:
    case FilterByType.ABNORMAL:
      filteredDataset = filteredDataset.filter(
        ({ type }) => type === filterByType
      );
      break;
    case FilterByType.DETECT_AND_CLASSIFY:
      console.log(filterByType);
      filteredDataset = filteredDataset.filter(
        ({ type }) => type === "DETECT_AND_CLASSIFY"
      );
      break;
    case FilterByType.ALL:
    default:
      break;
  }

  return (
    <Container>
      <PageTitle title="Datasets">
        <DatasetsControlPannel />
      </PageTitle>
      <DatasetsTable datasets={filteredDataset} isLoading={isDatasetPending} />
      {editTask === TableClassificationEditTask.RENAME && (
        <DatasetTableClassificationTableEditRename editTask={editTask} />
      )}
      {editTask === TableClassificationEditTask.DELETE && (
        <DatasetTableClassificationTableEditDelete editTask={editTask} />
      )}
      {editTask === TableClassificationEditTask.ADD && (
        <TableClassificationEditAdd editTask={editTask} />
      )}
    </Container>
  );
};

export default DatasetsContainer;

import { Modal, Button, Select,Form } from 'antd';
import React, { useState } from 'react';
import TasksValidTest from '../../components/Tasks-Valid-Test/index'

export interface ValidContainerProps {}

const TestContainer: React.SFC<ValidContainerProps> = () => {
  return (
    <>
      <TasksValidTest taskstitle="TestList" taskstype="test"></TasksValidTest>
    </>
  )
};

export default TestContainer;

import styled from "@emotion/styled";
import { Tag, Popconfirm } from "antd";
import { useActions } from "../../hooks/useActions";
import { TableClassificationEditTask } from "../../states/reducers/tableClassifications";
import { Classification } from "../../states/reducers/datasets";

const MyTag = styled(Tag)`
  margin-bottom: 2px;
  :hover {
    color: darkblue;
  }
`;

export interface DatasetClassificationProps {
  color: string;
  classification: Classification;
  datasetId: number;
}

const DatasetClassification: React.FC<DatasetClassificationProps> = ({
  color,
  classification,
  datasetId,
}) => {
  const { setClassificationEditOpen } = useActions();
  return (
    <Popconfirm
      title="Rename or Delete"
      okText="Rename"
      cancelText="Delete"
      onConfirm={() =>
        setClassificationEditOpen(
          TableClassificationEditTask.RENAME,
          classification,
          datasetId
        )
      }
      onCancel={() =>
        setClassificationEditOpen(
          TableClassificationEditTask.DELETE,
          classification,
          datasetId
        )
      }
      cancelButtonProps={{ type: "primary", danger: true }}
    >
      <MyTag color={color}>{classification.description.toUpperCase()}</MyTag>
    </Popconfirm>
  );
};

export default DatasetClassification;

import { Dispatch } from "redux";
import * as _ from "lodash";
import { ActionTypes } from "../action-types/index";
import * as Actions from "../actions";
import axios from "axios";
import { offlineUrl,offlineUrlExpress } from "../../urls/index";
import { message } from "antd";
import { TableClassificationEditTask } from "../reducers/tableClassifications";
import { Classification } from "../reducers/datasets";

export const setClassificationEditOpen = (
  edit: TableClassificationEditTask,
  classification: Classification | null,
  datasetId: number
): Actions.setClassificationEditOpen => ({
  type: ActionTypes.SET_TABLE_CLASSIFICATION_EDIT_OPEN,
  edit: edit,
  classification: classification,
  datasetId: datasetId,
});

export const setClassificationEditClose = () => ({
  type: ActionTypes.SET_TABLE_CLASSIFICATION_EDIT_CLOSE,
});

export const renameClassificationAsync = (
  classId: number,
  description: string
) => async (dispatch: Dispatch<Actions.TableClassificationsAction>) => {
  dispatch({
    type: ActionTypes.RENAME_CLASSIFICATION_START,
  });
  const jwt = localStorage.getItem("jwt");

  try {
    await axios.put(
      `${offlineUrl}/dataset-classifications/${classId}`,
      {
        description: description.trim(),
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
    dispatch({
      type: ActionTypes.RENAME_CLASSIFICATION_SUCCESS,
    });
    message.success("rename successed!");
  } catch (err:any) {
    const errMsg = [];

    if (err.response) {
      if (!_.isArray(err.response.data.message)) {
        errMsg.push(err.response.data.message);
      }
    } else {
      errMsg.push(err.message);
    }
    _.forEach(errMsg, (em) => message.error(em));
    dispatch({
      type: ActionTypes.RENAME_CLASSIFICATION_ERROR,
    });
  }
};

export const deleteClassificationAsync = (classId: number) => async (
  dispatch: Dispatch<Actions.TableClassificationsAction>
) => {
  dispatch({
    type: ActionTypes.DELETE_CLASSIFICATION_START,
  });
  const jwt = localStorage.getItem("jwt");

  try {
    await axios.delete(`${offlineUrl}/dataset-classifications/${classId}`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    dispatch({
      type: ActionTypes.DELETE_CLASSIFICATION_SUCCESS,
    });
    message.success("Classification Deleted!");
  } catch (err:any) {
    const errMsg = [];

    if (err.response) {
      if (!_.isArray(err.response.data.message)) {
        errMsg.push(err.response.data.message);
      }
    } else {
      errMsg.push(err.message);
    }
    _.forEach(errMsg, (em) => message.error(em));
    dispatch({
      type: ActionTypes.DELETE_CLASSIFICATION_ERROR,
    });
  }
};

export const addClassificationAsync = (
  datasetId: number,
  description: string
) => async (dispatch: Dispatch<Actions.TableClassificationsAction>) => {
  dispatch({
    type: ActionTypes.ADD_CLASSIFICATION_START,
  });
  const jwt = localStorage.getItem("jwt");

  try {
    await axios.post(
      `${offlineUrlExpress}/api/dataset-classifications/${datasetId}`,
      {
        description: description.trim(),
      }
    /*
    await axios.post(
      `${offlineUrl}/dataset-classifications/${datasetId}/dataset`,
      {
        description: description.trim(),
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    */      
    );    
    dispatch({
      type: ActionTypes.ADD_CLASSIFICATION_SUCCESS,
    });
    message.success("A New Classification Added!");
  } catch (err:any) {
    const errMsg = [];

    if (err.response) {
      if (!_.isArray(err.response.data.message)) {
        errMsg.push(err.response.data.message);
      }
    } else {
      errMsg.push(err.message);
    }
    _.forEach(errMsg, (em) => message.error(em));
    dispatch({
      type: ActionTypes.ADD_CLASSIFICATION_ERROR,
    });
  }
};

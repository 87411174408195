import { offlineUrl,offlineUrlExpress } from "../../urls/index";
import axios from "axios";
import { Dispatch } from "redux";
import * as Actions from "../actions";
import * as _ from "lodash";
import { ActionTypes } from "../action-types/index";
import { message } from "antd";

export const deleteDatasetByIdAsync = (datasetId: number) => async (
  dispatch: Dispatch<Actions.DeleteDatasetActions>
) => {
  dispatch({
    type: ActionTypes.DELETE_DATASET_START,
  });
  const jwt = localStorage.getItem("jwt");
  try {
    /*
    await axios.delete(`${offlineUrl}/datasets/${datasetId}`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    */
    await axios.delete(`${offlineUrlExpress}/api/dataset/${datasetId}`);
    dispatch({
      type: ActionTypes.DELETE_DATASET_SUCCESS,
    });
    message.success("Dataset has been delete!");
    setTimeout(() => {
      dispatch({
        type: ActionTypes.CLEAR_DELETE_DATASET_SUCCESS,
      });
    });
  } catch (err:any)  {
    const errMsg = [];
    if (!_.isArray(err.response.data.message)) {
      errMsg.push(err.response.data.message);
    }
    dispatch({
      type: ActionTypes.DELETE_DATASET_ERROR,
      error: errMsg,
    });
    setTimeout(() => {
      dispatch({
        type: ActionTypes.CLEAR_DELETE_DATASET_ERROR,
      });
    });
  }
};

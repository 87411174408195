import styled from "@emotion/styled";
import OfflineNestNavigation from "../../components/Offline-Nest-Navigation";
import { useRouteMatch, Switch, Route, Redirect } from "react-router-dom";
import DatasetsContainer from "../Datasets-Container";
import ModelsContainer from "../Models-Container";
import TasksContainer from "../Tasks-Container";
import TrainContaier from "../Train-Container"
import ValidContaier from "../Valid-Container"
import TestContaier from "../Test-Container"
import DemoContaier from "../Demo-Container"
import ServersContainer from "../Servers-Container";


const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: white;
`;
const ContentContainer = styled.div`
  width: 100%;
  padding: 0.8rem;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 768px) and (max-width: 1440px) {
    max-width: 1320px;
  }
  @media (min-width: 1440px) {
    max-width: 85%;
  }
`;

export interface OfflineHomeProps {}

const OfflineHome: React.FC<OfflineHomeProps> = () => {
  let { path } = useRouteMatch();
  return (
    <Container>
      <OfflineNestNavigation />
      <ContentContainer>
        <Switch>
          <Route path={`${path}/datasets`}>
            <DatasetsContainer />
          </Route>
          <Route path={`${path}/models`}>
            <ModelsContainer />
          </Route>
          <Route path={`${path}/train`}>
            <TrainContaier />
          </Route>
          <Route path={`${path}/valid`}>
            <ValidContaier />
          </Route>
          <Route path={`${path}/test`}>
            <TestContaier />
          </Route>
          <Route path={`${path}/demo`}>
            <DemoContaier />
          </Route>                                
          <Route path={`${path}/tasks`}>
            <TasksContainer />
          </Route>
          <Route path={`${path}/servers`}>
            <ServersContainer />
          </Route>          
          <Route exact path={`${path}`}>
            <Redirect to={`${path}/datasets`} />
          </Route>
        </Switch>
      </ContentContainer>
    </Container>
  );
};

export default OfflineHome;

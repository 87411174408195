import { useEffect } from "react";
import { Modal } from "antd";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import DatasetCard from "../Dataset-Card/index";
import { Popconfirm, Tag, message } from "antd";
import styled from "@emotion/styled";
import ImagesSelectForm from "../Images-Select-Form";
import { covertIsoDateToString } from "../../utils/index";

const MyTag = styled(Tag)`
  margin-bottom: 2px;
`;
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const SourceContainer = styled.div`
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
`;
const Source = styled.div`
  margin-right: 2px;
  margin-bottom: 5px;
`;
const SourceInner = styled.div`
  display: flex;
  flex-direction: column;
`;

export interface InsertImagesFormProps {
  datasetId: number;
}

const InsertImagesForm: React.FC<InsertImagesFormProps> = ({ datasetId }) => {
  const {
    imagesQueryGroup,
    isInsertPending,
    insertSuccess,
    imageCount,
  } = useTypedSelector((state) => state.insertImages);
  const {
    cancelImagesInserting,
    fetchSourceFoldersAsync,
    deleteSourceByIndex,
    insertingImagesAsync,
    fetchDatasetsAsync,
  } = useActions();

  useEffect(() => {
    if (insertSuccess) {
      message.success(`images inserted!! current total:${imageCount}`, 3);
      cancelImagesInserting();
    }
  }, [imageCount, insertSuccess, cancelImagesInserting]);

  useEffect(() => {
    return () => {
      fetchDatasetsAsync();
    };
  }, [fetchDatasetsAsync]);

  useEffect(() => {
    fetchSourceFoldersAsync();
  }, [fetchSourceFoldersAsync]);

  return (
    <Modal
      width={800}
      onCancel={cancelImagesInserting}
      onOk={insertingImagesAsync}
      confirmLoading={isInsertPending}
      visible={!!datasetId}
    >
      <InfoContainer style={{ marginTop: "1rem" }}>
        <DatasetCard datasetId={datasetId} />
        <SourceContainer>
          {imagesQueryGroup.map((g, i) => (
            <Source key={`source+${i + 1}`}>
              <Popconfirm
                title="delete this source?"
                onConfirm={() => deleteSourceByIndex(i)}
                // onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <SourceInner>
                  <MyTag color="geekblue">source: {g.origin_folder}</MyTag>
                  <MyTag color="gold">count: {g.count}</MyTag>
                  {g.search && <MyTag color="purple">seach: {g.search}</MyTag>}
                  {g.minDate && (
                    <MyTag color="volcano">
                      min:{" "}
                      {
                        covertIsoDateToString(g.minDate)
                          .join("  ")
                          .split("+")[0]
                      }
                    </MyTag>
                  )}
                  {g.maxDate && (
                    <MyTag color="magenta">
                      max:{" "}
                      {
                        covertIsoDateToString(g.maxDate)
                          .join("  ")
                          .split("+")[0]
                      }
                    </MyTag>
                  )}
                </SourceInner>
              </Popconfirm>
            </Source>
          ))}
        </SourceContainer>
      </InfoContainer>
      <ImagesSelectForm />
    </Modal>
  );
};

export default InsertImagesForm;

import styled from "@emotion/styled";
import { Button } from "antd";
import { useActions } from "../../hooks/useActions";
import CreateDatasetForm from "../Create-Dataset-Form";
import InsertImagesForm from "../Insert-Images-Form";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import DatasetFilterOption from "../Dataset-filter-options";

const PannelContainer = styled.div`
  margin-left: 2rem;
  flex: 1;
  display: flex;
  align-items: center;
`;

export interface DatasetsControlPannelProps {}

const DatasetsControlPannel: React.FC<DatasetsControlPannelProps> = () => {
  const { startCreateDataset } = useActions();
  const { isModalOpen: isCreateDatasetModelOpen } = useTypedSelector(
    (state) => state.createDataset
  );
  const { datasetId: toInsertDatasetId } = useTypedSelector(
    (state) => state.insertImages
  );
  return (
    <PannelContainer>
      <DatasetFilterOption />
      <Button onClick={startCreateDataset}>Create A Dataset</Button>
      {isCreateDatasetModelOpen && <CreateDatasetForm />}
      {toInsertDatasetId && <InsertImagesForm datasetId={toInsertDatasetId} />}
    </PannelContainer>
  );
};

export default DatasetsControlPannel;

import { Button } from "antd";
import { useState } from "react";
import TaskResultExportModal from "../TaskResult-Export-Modal";
import { ConditionType, DatasetDetail } from "../TestResult-Virtual-Display";

interface TaskResultExportType {
  imageElements: DatasetDetail[];
  taskId: string;
  condition: ConditionType;
  detail: any;
}

const ExportButton: React.FC<TaskResultExportType> = ({
  imageElements,
  taskId,
  condition,
  detail,
}) => {
  const [showExport, setShowExport] = useState<boolean>(false);

  const handleModalClose = () => setShowExport(false);

  return (
    <>
      <Button onClick={() => setShowExport(true)} style={{ marginTop: 2 }}>
        Export to Dataset
      </Button>
      <TaskResultExportModal
        imageData={imageElements}
        show={showExport}
        taskId={taskId}
        condition={condition}
        detail={detail}
        handleClose={handleModalClose}
      />
    </>
  );
};

export default ExportButton;

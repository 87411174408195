import IframeResizer from 'iframe-resizer-react'
export interface TrainContainerProps {}


const TrainContainer: React.SFC<TrainContainerProps> = () => {
  return (
    <div>
    <IframeResizer
    log
    inPageLinks
    //forwardRef={ref}        
    //onMessage={onMessage}
    //onResized={onResized}        
    src="CreateModel.html"
    width="100%"
    //scrolling="true"
    height="1000px"
    frameBorder="0"
    />
    </div>  
    );
};

export default TrainContainer;

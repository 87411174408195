import { ActionTypes } from "../action-types";
import * as Actions from "../actions";
export interface SourceFolder {
  id: number;
  folder: string;
  origin: string;
  create_at: string;
  origin_folder: string;
  sourceFolderId: number;
  count: number;
  imageIds?: number[];
  max?: string;
  min?: string;
}

export interface SourceImagesQueryGroup extends SourceFolder {
  sourceFolder: string;
  search?: string;
  minDate?: string;
  maxDate?: string;
  imageIds: number[];
}

export interface SourceImagesQueryFilterGroup extends SourceFolder {
  sourceFolder: string;
  search?: string;
  filter?: Array<string>;
  minDate?: string;
  maxDate?: string;
  imageIds: number[];
}

export interface InsetImagesState {
  datasetId: number | null;
  imageCount: number;
  sourceFolder: SourceFolder[];
  search: string;
  minDate: Date | null;
  maxDate: Date | null;
  imagesQueryGroup: SourceImagesQueryGroup[];
  isSourceFolderPending: boolean;
  isInsertPending: boolean;
  isSourceImagesQueryPending: boolean;
  error: string[];
  success: boolean;
  insertSuccess: boolean;
}

const INIT_STATE: InsetImagesState = {
  datasetId: null,
  imageCount: 0,
  sourceFolder: [],
  search: "",
  minDate: null,
  maxDate: null,
  imagesQueryGroup: [],
  isSourceFolderPending: false,
  isInsertPending: false,
  isSourceImagesQueryPending: false,
  error: [],
  success: false,
  insertSuccess: false,
};

const reducer = (state = INIT_STATE, action: Actions.InsertImagesAction) => {
  switch (action.type) {
    case ActionTypes.DELETE_SOURCE_BY_INDEX:
      const updatedImagesQueryGroup = [...state.imagesQueryGroup];
      updatedImagesQueryGroup.splice(action.index, 1);
      return {
        ...state,
        imagesQueryGroup: updatedImagesQueryGroup,
      };
    case ActionTypes.SET_DATASET_ID_FOR_INSERTING:
      return {
        ...INIT_STATE,
        datasetId: action.datasetId,
      };
    case ActionTypes.CLEAR_ADD_IMAGES_ERROR:
      return {
        ...state,
        error: [],
      };
    case ActionTypes.CLEAR_ADD_IMAGES_SUCCESS:
      return {
        ...state,
        success: false,
      };
    case ActionTypes.CANCEL_IMAGES_INSERTING:
      return {
        ...INIT_STATE,
      };
    case ActionTypes.FETCH_SOURCE_FOLDER_START:
      return {
        ...state,
        isSourceFolderPending: true,
      };
    case ActionTypes.FETCH_SOURCE_FOLDER_SUCCESS:
      return {
        ...state,
        isSourceFolderPending: false,
        sourceFolder: action.sourceFolder,
      };
    case ActionTypes.FETCH_SOURCE_FOLDER_ERROR:
      return {
        ...state,
        isSourceFolderPending: false,
        error: action.error,
      };
    case ActionTypes.ADD_IMAGES_TO_GROUP_START:
      return {
        ...state,
        isSourceImagesQueryPending: true,
      };
    case ActionTypes.ADD_IMAGES_TO_GROUP_SUCCESS:
      return {
        ...state,
        isSourceImagesQueryPending: false,
        imagesQueryGroup: [...state.imagesQueryGroup, action.group],
        success: true,
      };
    case ActionTypes.ADD_IMAGES_TO_GROUP_ERROR:
      return {
        ...state,
        isSourceImagesQueryPending: false,
        error: action.error,
      };
    case ActionTypes.INSERTING_IMAGES_START:
      return {
        ...state,
        isInsertPending: true,
      };
    case ActionTypes.INSERTING_IMAGES_SUCCESS:
      return {
        ...state,
        isInsertPending: false,
        insertSuccess: true,
        imageCount: action.updateCount,
      };
    case ActionTypes.INSERTING_IMAGES_ERROR:
      return {
        ...state,
        isInsertPending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default reducer;

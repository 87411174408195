import styled from "@emotion/styled";
import { Button } from "antd";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { FilterByOwn, FilterByType } from "../../states/reducers/datasets";
import { useActions } from "../../hooks/useActions";
import { fullFunction } from "../../urls/index";

const DatasetFilterContainer = styled.div`
  flex: 1;
  display: flex;
`;
const GroupDiv = styled.div`
  margin-right: 2rem;
`;

export interface DatasetFilterOptionProps {}

const DatasetFilterOption: React.FC<DatasetFilterOptionProps> = () => {
  const { filterByOwn, filterByType } = useTypedSelector(
    (state) => state.datasets
  );
  const { changeDatasetFilterByOwn, changeDatasetFilterByType } = useActions();

  return (
    <DatasetFilterContainer>
      <GroupDiv>
        <Button
          shape="round"
          style={{ marginRight: "1px" }}
          onClick={() => changeDatasetFilterByOwn(FilterByOwn.USER)}
          danger={filterByOwn === FilterByOwn.USER}
        >
          USER
        </Button>
        <Button
          shape="round"
          style={{ marginRight: "1px" }}
          onClick={() => changeDatasetFilterByOwn(FilterByOwn.OTHERS)}
          danger={filterByOwn === FilterByOwn.OTHERS}
        >
          OTHERS
        </Button>
        <Button
          shape="round"
          style={{ marginRight: "1px" }}
          onClick={() => changeDatasetFilterByOwn(FilterByOwn.ALL)}
          danger={filterByOwn === FilterByOwn.ALL}
        >
          ALL
        </Button>
      </GroupDiv>
      <GroupDiv>
        <Button
          shape="round"
          style={{ marginRight: "1px" }}
          onClick={() => changeDatasetFilterByType(FilterByType.ALL)}
          danger={filterByType === FilterByType.ALL}
        >
          ALL
        </Button>
        <Button
          shape="round"
          style={{ marginRight: "1px" }}
          onClick={() => changeDatasetFilterByType(FilterByType.DETECT)}
          danger={filterByType === FilterByType.DETECT}
        >
          DETECT
        </Button>
        {/* Function Remark */}
        {fullFunction &&  
        <Button
          shape="round"
          style={{ marginRight: "1px" }}
          onClick={() => changeDatasetFilterByType(FilterByType.CLASSIFY)}
          danger={filterByType === FilterByType.CLASSIFY}
        >
          CLASSIFY
        </Button>
        }
        <Button
          shape="round"
          style={{ marginRight: "1px" }}
          onClick={() =>
            changeDatasetFilterByType(FilterByType.DETECT_AND_CLASSIFY)
          }
          danger={filterByType === FilterByType.DETECT_AND_CLASSIFY}
        >
          DETECT&CLASSIFY
        </Button>
        {/* Function Remark */}
        {fullFunction &&          
        <Button
          shape="round"
          style={{ marginRight: "1px" }}
          onClick={() => changeDatasetFilterByType(FilterByType.ABNORMAL)}
          danger={filterByType === FilterByType.ABNORMAL}
        >
          ABNORMAL
        </Button>
        }


      </GroupDiv>
    </DatasetFilterContainer>
  );
};

export default DatasetFilterOption;

import { Form, Input, Modal } from "antd";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import { TableClassificationEditTask } from "../../states/reducers/tableClassifications";
import { useEffect } from "react";
export interface ClassificationEditAddProps {
  editTask: TableClassificationEditTask;
}
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const ClassificationEditAdd: React.FC<ClassificationEditAddProps> = ({
  editTask,
}) => {
  const [form] = Form.useForm();
  const {
    setClassificationEditClose,
    addClassificationAsync,
    fetchDatasetsAsync,
  } = useActions();
  useEffect(() => {
    return () => {
      fetchDatasetsAsync();
    };
  }, [fetchDatasetsAsync]);

  const { isClassificationUpdating, datsetId } = useTypedSelector(
    (state) => state.classifications
  );
  const onFinish = () => {
    const { description } = form.getFieldsValue();
    console.log(description);
    addClassificationAsync(datsetId!, description);
  };
  return (
    <Modal
      title="Add A New Classification To This Dataset"
      confirmLoading={isClassificationUpdating}
      onCancel={setClassificationEditClose}
      onOk={onFinish}
      visible={editTask !== TableClassificationEditTask.NONE}
    >
      <Form
        {...layout}
        form={form}
        name="basic"
        initialValues={{ description: "" }}
      >
        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
              message: "Please input a new description for this class!",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ClassificationEditAdd;

import { ActionTypes } from "../action-types/index";
import * as Actions from "../actions";
import { Classification } from "./datasets";

export enum TableClassificationEditTask {
  DELETE = "DELETE",
  RENAME = "RENAME",
  ADD = "ADD",
  NONE = "NONE",
}

interface TableClassificationState {
  isClassificationUpdating: boolean;
  editTask: TableClassificationEditTask;
  targetClassification: null | Classification;
  datsetId: null | number;
}

const INIT_STATE: TableClassificationState = {
  isClassificationUpdating: false,
  editTask: TableClassificationEditTask.NONE,
  targetClassification: null,
  datsetId: null,
};

const reducer = (
  state = INIT_STATE,
  action: Actions.TableClassificationsAction
) => {
  switch (action.type) {
    case ActionTypes.ADD_CLASSIFICATION_START:
    case ActionTypes.RENAME_CLASSIFICATION_START:
    case ActionTypes.DELETE_CLASSIFICATION_START:
      return {
        ...state,
        isClassificationUpdating: true,
      };
    case ActionTypes.ADD_CLASSIFICATION_SUCCESS:
    case ActionTypes.RENAME_CLASSIFICATION_SUCCESS:
    case ActionTypes.DELETE_CLASSIFICATION_SUCCESS:
    case ActionTypes.SET_TABLE_CLASSIFICATION_EDIT_CLOSE:
      return INIT_STATE;
    case ActionTypes.ADD_CLASSIFICATION_ERROR:
    case ActionTypes.RENAME_CLASSIFICATION_ERROR:
    case ActionTypes.DELETE_CLASSIFICATION_ERROR:
      return {
        ...state,
        isClassificationUpdating: false,
      };
    case ActionTypes.SET_TABLE_CLASSIFICATION_EDIT_OPEN:
      return {
        ...state,
        editTask: action.edit,
        targetClassification: action.classification,
        datsetId: action.datasetId,
      };
    default:
      return state;
  }
};

export default reducer;

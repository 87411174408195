import styled from "@emotion/styled";
import SignInAndSignUp from "../../components/Sign-in-And-Sign-Up";
import bg from "../../assest/auth-bg.jpg";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${bg});
  background-position: center;
`;

export interface AuthPageProps {}

const AuthPage: React.FC<AuthPageProps> = () => {
  return (
    <Container>
      <SignInAndSignUp />
    </Container>
  );
};

export default AuthPage;

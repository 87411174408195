import styled from "@emotion/styled";
import { Typography } from "antd";
const { Title } = Typography;

const PageTitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  @media (min-width: 768px) {
    padding-top: 0.6rem;
  }
`;

const MyTitle = styled(Title)`
  @media (min-width: 768px) {
    --padding-top: 0.6rem;
    font-size: 46px;
    line-height: 1.2;
  }
`;

export interface PageTitleProps {
  title: string;
}

const PageTitle: React.SFC<PageTitleProps> = ({ title, children }) => {
  return (
    <PageTitleContainer>
      <MyTitle>{title}</MyTitle>
      {children}
    </PageTitleContainer>
  );
};

export default PageTitle;
